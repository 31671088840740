import { CalendarIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/20/solid'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Inventur = () => {


    const [inventur, setInventur] = useState([])
    const [locations, setLocations] = useState([])
    const [selectedLocation, setSelectedLocation] = useState(locations[0]?.id)
    const [loaded, setLoaded] = useState(false)

    console.log(locations[0]?.id)

      useEffect(() => {
        axios.get("https://meisen.waithere.de/api/inventur")
            .then((res)=>{
                setInventur(res.data)
            })
        axios.get("https://meisen.waithere.de/api/locations")
        .then((res)=>{
            setLocations(res.data)
        })
      }, [loaded])

      const newInventur = () =>{
        try{

            axios.post("https://meisen.waithere.de/api/inventur",{
                stock_id: selectedLocation
            })
            .then((res)=>{
                setLoaded(!loaded)
                console.log(res.data)
            })

        }catch(error){
            console.log(error)
        }
      }
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });

  return (
    <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
                        
            <div className="text-center mt-10">
                <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                >
                    <path
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    />
                </svg>
                <h3 className="mt-2 text-sm font-semibold text-gray-900">{inventur.length > 0 ? "Letzte Inventur am "+new Date(inventur[inventur.length-1].date).toLocaleDateString('de-DE'):"Keine Inventur erstellt"}</h3>
                <p className="mt-1 text-sm text-gray-500">Um eine aktuelle Inventur zu erstellen, auf Button klicken</p>
                <div className="mt-6 grid grid-cols-2 gap-6">
                    
                    
                    <div className='col-span-2 sm:col-span-1'>
                   
                    <select
                        id="location"
                        name="location"
                        className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={selectedLocation}
                        onChange={(e)=>setSelectedLocation(e.target.value)}
                    >
                        <option value="">Standort wählen</option>
                        {locations.map((location) =>{
                            return <option value={location.id}>{location.name}</option>
                            })
                            }
                    </select>
                    </div>
                    <div className='col-span-2 sm:col-span-1'>
                        <button
                        type="button"
                        onClick={()=>{newInventur()}}
                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                        Inventur erstellen
                        </button>
                    </div>
                </div>
                </div>
                {inventur.length > 0 &&
                    <div className="overflow-hidden bg-gray-50 shadow sm:rounded-md">
                        <ul role="list" className="divide-y divide-gray-200">
                            {inventur.reverse().map((position) => (
                            <li key={position.id}>
                                <Link to={`inventur/${position.id}`} className="block hover:bg-gray-100">
                                <div className="flex items-center px-4 py-4 sm:px-6">
                                    <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                    <div className="truncate">
                                        <div className="flex text-sm">
                                        <p className="truncate font-medium text-indigo-600">Vollständige Inventur</p>
                                        <p className="ml-1 flex-shrink-0 font-normal text-gray-500">in {position.stock_name}</p>
                                        </div>
                                        <div className="mt-2 flex">
                                        <div className="flex items-center text-sm text-gray-500">
                                            <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                            <p>
                                            Erstellt am <time dateTime={position.date}>{new Date(position.date).toLocaleDateString('de-DE')}</time>
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 flex-shrink-0 sm:ml-5 sm:mt-0">
                                        <div className="flex -space-x-1 overflow-hidden">
                                        <span className='block truncate text-sm font-medium text-gray-900'>
                                        {formatter.format((position.data.reduce(function (acc, obj) { return acc + parseFloat(obj.summe); }, 0)))}
                                        </span>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="ml-5 flex-shrink-0">
                                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                </div>
                                </Link>
                            </li>
                            ))}
                        </ul>
                        </div>

                }
              
            </div>
        </div>
    </div>
  )
}

export default Inventur