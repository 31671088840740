import React, { useState, Fragment, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3BottomLeftIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { BellIcon, MagnifyingGlassIcon, WifiIcon } from '@heroicons/react/20/solid'
import axios from 'axios'
import { Detector, Offline, Online } from "react-detect-offline";



  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }  

const Navbar = (props) => {

  let navigate = useNavigate()
  

  const userNavigation = [
    { name: 'Einstellungen', href: 'settings'},
  ]


  const Logout = async () => {
    try {
        await axios.delete(`https://meisen.waithere.de/api/logout`);
        navigate("/login");
    } catch (error) {
        console.log(error);
    }
}

  return (
    
            <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-gray-100 drop-shadow sm:bg-transparent">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => props.setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex flex-1 justify-end px-4 md:px-0">
                
                <div className="ml-4 flex items-center md:ml-6">
                
                  
                <Online>
                  <div className="rounded-md bg-green-50 p-2">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <WifiIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">Online</p>
                  </div>
                </div>
                </div>
                </Online>
                <Offline>
                  <div className="rounded-md bg-red-50 p-2">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <WifiIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-red-800">Offline</p>
                  </div>
                </div>
                </div>
                </Offline>


                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex max-w-xs items-center rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                        <span className="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100">
        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      </span>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <Link
                                to={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block py-2 px-4 text-sm text-gray-700'
                                )}
                              >
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                          
                        ))}

                        <Menu.Item key="2">
                            {({ active }) => (
                              <button
                                onClick={()=>window.location.reload(true)}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block py-2 px-4 text-sm text-gray-700 text-left w-full'
                                )}
                              >
                                App neu laden
                              </button>
                            )}
                        </Menu.Item>
                        <Menu.Item key="3">
                            {({ active }) => (
                              <button
                                onClick={()=>Logout()}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block py-2 px-4 text-sm text-gray-700 text-left w-full'
                                )}
                              >
                                Logout
                              </button>
                            )}
                          </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>



  )
}

export default Navbar