import { Combobox } from '@headlessui/react'
import { ChevronUpDownIcon, XCircleIcon, CheckIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import HeaderBar from '../../components/HeaderBar'

const EditStock = () => {

    const [query, setQuery] = useState('')
    const [selectedPerson, setSelectedPerson] = useState(null)
    const [zustand, setRadioValue] = useState('Neu');
    const [productFee, setProductFee] = useState("0.00")
    const [msg, setMsg] = useState("")
    const navigate = useNavigate()
    const [people, setPeople] = useState([])
    const [category, setCategory] = useState([])
    const [location, setLocation] = useState([])
    const [user, setUser] = useState([])
    const [product, setProduct] = useState([])
    const [color, setColor] = useState([])
    const [locationQuery, setLocationQuery] = useState('')
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [productQuery, setProductQuery] = useState('')
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [colorQuery, setColorQuery] = useState('')
    const [selectedColor, setSelectedColor] = useState(null)
    const [categoriesQuery, setCategoriesQuery] = useState('')
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [menge, setMenge] = useState("")
    const [operator, setOperator] = useState("")
    const [preview, setPreview] = useState([])
    const [button, setButton] = useState()
    const [productName, setProductName] = useState(null)
    const [filteredColors, setFilteredColors] = useState(color)


    useEffect(() => {
      
        try{
          axios.get("https://meisen.waithere.de/api/locations")
          .then((res)=>{
              setLocation(res.data)
          })
        }catch(error){
          console.log(error)
        }
      
      }, [])

      useEffect(() => {
      
        try{
         axios.get("https://meisen.waithere.de/api/categories")
          .then((res)=>{
              setCategory(res.data)
          })
        }catch(error){
          console.log(error)
        }
      
      }, [])

      useEffect(() => {
      
        try{
          axios.get(`https://meisen.waithere.de/api/all-products?category=${selectedCategory}`)
          .then((res)=>{
              setProduct(res.data.products)
              setColor(res.data.colors)
          })
        }catch(error){
          console.log(error)
        }
      
      }, [selectedCategory])

      useEffect(() => {
        setMenge("")
        // setPreview([])
        try{
          axios.get(`https://meisen.waithere.de/api/colors?location=${selectedLocation}&product=${selectedProduct}&color=${selectedColor}&category=${selectedCategory}`)
          .then((res)=>{
              setPreview(res.data.colors)
              if(res.data.colors[0]?.availables[0]?.available <= 0){setOperator("r")}else{setOperator("")}
          })
        }catch(error){
          console.log(error)
        }
      
      }, [selectedCategory, selectedProduct, selectedColor, selectedLocation])
  
    const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    });      

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }    

    const UpdateBestand = (e) => {

        e.preventDefault()

        try{
            axios.post(`https://meisen.waithere.de/api/update`,{
                stock: selectedLocation,
                product: selectedProduct,
                color: selectedColor,
                operator: operator,
                value: menge,
                category: selectedCategory
            })
            .then((res)=>{
                setButton({color:"green",text:"Erfolgreich Aktualisiert"})
                setTimeout(() => {
                    setButton()
                    setMenge("")
                    setOperator("")
                }, 1500);
                
            }).catch((res) => {
                console.log(res)
                setMsg(res.response.data)
              });
          }catch(error){
            console.log(error)
          }
    }

    const handleProductChange = (e) => {
      

      const productId = e.target.value;
      const foundProduct = product.find(p => p.id === parseInt(productId));
    

      if (foundProduct) {
        setSelectedProduct(foundProduct); // Store the entire product object
    
        // Split the product name and extract the last keyword
        const productKeywords = foundProduct.name.split(' ');
        const lastKeyword = productKeywords[productKeywords.length - 1];
    
        // Check if the last keyword is "cm", "Bonds", or "Wavy"
        if (["cm", "Bonds", "Wavy", "Groß", "Klein", "Brush", "(klein)", "L", "XL", "M"].includes(lastKeyword)) {
          // If so, exclude colors containing "Rooted", "Two Tone", "Ombre", or "Blended"
          setFilteredColors(color.filter(c => !c.name.includes("Rooted") && !c.name.includes("Two Tone") && !c.name.includes("Ombre") && !c.name.includes("Blended")));
        } else {
          // Otherwise, include only colors that contain the last keyword
          setFilteredColors(color.filter(c => c.name.includes(lastKeyword)));
        }
      } else {
        setSelectedProduct(null);
        setFilteredColors(color); // Show all colors if no product is selected
      }
      setSelectedProduct(e.target.value);

    };


  return (
    <>
    <HeaderBar title="Bestand aktualisieren" />
      <div className="lg:px-0">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <p className="mt-2 text-sm text-gray-700">
            Aktualisieren Sie bestehenden Bestand außerhalb eines Beleges.
          </p>
        </div>
        
      </div>
      </div>
      <div className="mt-10">

        <form onSubmit={(e)=>UpdateBestand(e)} autoComplete="off">
            <div className="shadow sm:rounded-md">
            <div className="bg-white px-4 py-5 sm:p-6">
                {msg &&
                <div className="rounded-md bg-red-50 p-4 mb-2">
                <div className="flex">
                    <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">Fehler beim aktualisieren</h3>
                    <div className="mt-2 text-sm text-red-700">
                        <ul role="list" className="list-disc space-y-1 pl-5">
                        <li>{msg}</li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
                }
                <div className="grid grid-cols-6 gap-6">

                <div className="col-span-3 sm:col-span-3">
                <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-700">
                    Standort auswählen
                </label>
                <select
                    id="location"
                    name="location"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={selectedLocation?selectedLocation:"Bitte wählen..."}
                    onChange={e=>setSelectedLocation(e.target.value)}
                >
                    <option value="Bitte wählen..." disabled selected>Bitte wählen...</option>
                    {location.map((location) =>{
                            return <option value={location.id}>{location.name}</option>
                            })
                    }
                </select>
                </div>    

                <div className="col-span-3 sm:col-span-3">
                <label htmlFor="categorie" className="block text-sm font-medium leading-6 text-gray-700">
                    Produktkategorie wählen
                </label>
                <select
                    id="categorie"
                    name="location"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={selectedCategory?selectedCategory:"Bitte wählen..."}
                    onChange={e=>setSelectedCategory(e.target.value)}
                >
                    <option value="Bitte wählen..." disabled selected>Bitte wählen...</option>
                    {category.map((location) =>{
                            return <option value={location.id}>{location.name}</option>
                            })
                    }
                </select>
                </div>  

                <div className="col-span-3 sm:col-span-3">
                <label htmlFor="product" className="block text-sm font-medium leading-6 text-gray-700">
                    Produkt wählen
                </label>
                <select
                    id="product"
                    name="location"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={selectedProduct?selectedProduct:"Bitte wählen..."}
                    onChange={e=>handleProductChange(e)} 
                >
                    <option value="Bitte wählen..." disabled selected>Bitte wählen...</option>
                    {product.map((location) =>{
                            return <option value={location.id}>{location.name}</option>
                            })
                    }
                </select>
                </div>  

                <div className="col-span-3 sm:col-span-3">
                <label htmlFor="color" className="block text-sm font-medium leading-6 text-gray-700">
                    Farbe wählen
                </label>
                <select
                    id="color"
                    name="location"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={selectedColor?selectedColor:"Bitte wählen..."}
                    onChange={e=>setSelectedColor(e.target.value)}
                >
                    <option value="Bitte wählen..." disabled selected>Bitte wählen...</option>
                    {filteredColors.map((location) =>{
                            return <option value={location.id}>{location.name}</option>
                            })
                    }
                </select>
                </div>  
                

                    <div className='col-span-6'>
                    <label htmlFor="menge" className="block text-sm font-medium leading-6 text-gray-900">
                        Menge verändern, aktuell verfügbar: {preview.length >0 ? preview[0]?.availables[0]?.available: "Kein Bestand gefunden"}
                    </label>
                    <div className="mt-2 flex rounded-md shadow-sm">
                        <div className="relative flex flex-grow items-stretch focus-within:z-10">
                        <input
                            type="menge"
                            name="menge"
                            id="menge"
                            className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Bestand ändern oder erstellen"
                            value={menge}
                            onChange={(e)=>{setMenge(e.target.value);setMsg("")}}
                            autoComplete="off"
                        />
                        </div>
                        
                        <button
                        type="button"
                        className={classNames("relative -ml-px inline-flex items-center gap-x-1.5 px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50", operator === "+" ? "ring-indigo-600 text-gray-900 z-10" : "text-gray-400")}
                        onClick={()=>{setOperator("+")}}
                        >
                        <PlusIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Addieren
                        </button>
                        <button
                        type="button"
                        className={classNames("relative -ml-px inline-flex items-center gap-x-1.5 px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50", operator === "-" ? "ring-indigo-600 text-gray-900 z-10" : "text-gray-400")}
                        onClick={()=>{setOperator("-")}}

                        >
                        <MinusIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Abziehen
                        </button>
                        <button
                        type="button"
                        className={classNames("relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50", operator === "r" ? "ring-indigo-600 text-gray-900" : "text-gray-400")}
                        onClick={()=>{setOperator("r")}}

                        >
                        <MinusIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Ersetzen
                        </button>
                    </div>
                    </div>

                            

                            

                           
                        </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                        <button
                            type="submit"
                            className={classNames("inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2", button ? "bg-green-600 focus:ring-green-500 hover:bg-green-700":"bg-indigo-600 focus:ring-indigo-500 hover:bg-indigo-700")}
                        >
                            {button ? button.text: `Absenden`}
                        </button>
                        </div>
                    </div>
                    </form>

                </div>
    </>
  )
}

export default EditStock