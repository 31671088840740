import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { Role } from './Roles';
import AdminNotice from './AdminNotice';
import GlobalSpinner from './GlobalSpinner';

const PrivateRoute = (props, { children }) => {

    const [role, setRole] = useState(null);
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [users, setUsers] = useState([]);
    let navigate = useNavigate()
 
    useEffect(() => {
        refreshToken();
    }, [props]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`https://meisen.waithere.de/api/token`);
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.role);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/login");
            }
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`https://meisen.waithere.de/api/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.role);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });


    // if(role !== null && !props.role){
    // return React.Children.map(props.children, (child)=>React.cloneElement(child, {token, role}))
    // } else if(role && props.role !== role){
    //   return navigate("/create")
    // } else if(role && props.role === role) {
    //     return React.Children.map(props.children, (child)=>React.cloneElement(child, {token, role}))

    // } 
    if(role === null) {
        return <GlobalSpinner/>
    }

    if(role !== null){
        if((props.role && props.role === role) || role === "Admin") return React.Children.map(props.children, (child)=>React.cloneElement(child, {token, role}))
        if(props.role && props.role !== role) return <AdminNotice/>
        return React.Children.map(props.children, (child)=>React.cloneElement(child, {token, role}))
    } 
    
    
  };

export default PrivateRoute;