import { MinusCircleIcon, MinusIcon, XCircleIcon } from '@heroicons/react/20/solid'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

const AdressBook = () => {

    const [locations, setLocations] = useState([])
    const [firma, setFirma] = useState("")
    const [street, setStreet] = useState("")
    const [city, setCity] = useState("")
    const [short, setShort] = useState("")
    const [bestand, setBestand] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [error, setError] = useState("")

    useEffect(() => {
      try{
        axios.get("https://meisen.waithere.de/api/get-address")
        .then((res)=>{
            setLocations(res.data)
        })

      }catch(error){
        console.log(error)
      }
    
    }, [loaded])
    
    const addAdress = async(e) => {
        e.preventDefault()
        if(!(firma && city && street)){
            setError("Bitte alle Felder ausfüllen");
            setTimeout(() => {
                setError("");
            }, 1000);
            
            return
        }
        try {
            await axios.post(`https://meisen.waithere.de/api/new-address`, {
                firma: firma,
                street: street,
                city: city,
                bestand: bestand,
                short: short
            })
            .then((res)=>{
                setLoaded(!loaded)
            })
        } catch (error) {
            if (error.response) {
            }
        }
        
    }
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }   

  return (
    <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">

                    <div className="shadow sm:overflow-hidden sm:rounded-md">
                        <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
                        

              <div className="mx-auto max-w-md sm:max-w-3xl mt-10">
                <div>
                    <div className="text-center">
                    <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                >
                    <path
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    />
                </svg>
                    <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">Adresse hinzufügen</h2>
                    <p className="mt-1 text-sm text-gray-500">Verwalten Sie bestehende Standorte und Adressen oder fügen Sie neue hinzu.</p>
                    </div>
                    <form onSubmit={addAdress} className="mt-6 md:col-span-2">

                            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                            <div className="col-span-full">
                                <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                                Firmenname 
                                </label>
                                <div className="mt-2">
                                <input
                                    type="text"
                                    name="street-address"
                                    id="street-address"
                                    autoComplete="street-address"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={e=>setFirma(e.target.value)}
                                    value={firma}
                                />
                                </div>
                            </div>

                            <div className="sm:col-span-3 sm:col-start-1">
                                <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                                Straße
                                </label>
                                <div className="mt-2">
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    autoComplete="address-level2"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder='Musterstraße 69'
                                    onChange={e=>setStreet(e.target.value)}
                                    value={street}
                                />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                                PLZ und Stadt
                                </label>
                                <div className="mt-2">
                                <input
                                    type="text"
                                    name="region"
                                    id="region"
                                    autoComplete="address-level1"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder='12345 Berlin'
                                    onChange={e=>setCity(e.target.value)}
                                    value={city}
                                />
                                </div>
                            </div>

                            <div className='sm:col-span-4'>
                            <fieldset>
                            <div className="mt-6 space-y-6">
                            <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                <input
                                    id="comments"
                                    name="comments"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    onChange={e=>setBestand(e.target.checked)}
                                    checked={bestand}
                                />
                                </div>
                                <div className="text-sm leading-6">
                                <label htmlFor="comments" className="font-medium text-gray-900">
                                    Bestand anlegen
                                </label>
                                <p className="text-gray-500">Soll für diese Adresse ein eigener Bestand angelegt werden?</p>
                                </div>
                            </div>
                            
                            </div>
              </fieldset>

                            </div>
                            <div className={classNames('sm:col-span-2', bestand?'opacity-100':'opacity-30')}>
                            <label htmlFor="short" className="block text-sm font-medium leading-6 text-gray-900">
                                Name des Bestands 
                                </label>
                                <div className="mt-2">
                                <input
                                    type="text"
                                    name="street-address"
                                    id="short"
                                    autoComplete="street-address"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    disabled={bestand?false:true}
                                    onChange={e=>setShort(e.target.value)}
                                    value={short}
                                />
                                </div>

                            </div>

                            
                            </div>
                            {error &&
                            <div className="mt-6 rounded-md bg-red-50 p-4 mb-2">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                <h3 className="text-sm font-medium text-red-800">Fehler beim anlegen der Adresse</h3>
                                <div className="mt-2 text-sm text-red-700">
                                    <ul role="list" className="list-disc space-y-1 pl-5">
                                    <li>{error}</li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                            </div>
                            }
                        <div className="mt-6 flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            
                            <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Zurücksetzen
                            </button>
                            <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                            Anlegen
                            </button>
                        </div>
                    </form>
                </div>
                
                    <div>
                        <h2 className="text-sm font-medium leading-6 text-gray-900">Adressübersicht</h2>

                        <ul role="list" className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                        {locations.map((person, personIdx) => (
                            <li key={personIdx} className="flex justify-between gap-x-6 py-4">
                            
                            <span className="block min-w-0 flex-1">
                                <span className="block truncate text-sm font-medium text-gray-900">{person.firma}</span>
                                <span className="block truncate text-sm font-medium text-gray-500">{person.straße}, {person.stadt}</span>
                            </span>    
                            
                            {person.altadress?
                            <button type="button" className="hidden sm:block font-semibold text-indigo-600 hover:text-indigo-500">
                                Bestand erstellen
                            </button>:<span className='font-semibold text-gray-900'>Standardadresse</span>}
                            <button className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
                            <MinusCircleIcon className="h-5 w-5 text-red-400 group-hover:text-gray-500" aria-hidden="true" />
                            </button>
                            </li>
                        ))}
                           
                        </ul>

                        <div className="flex border-t border-gray-100 pt-6">
                            <button type="button" className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                            <span aria-hidden="true">+</span> Adresse hinzufügen
                            </button>
                        </div>
                    </div>
                </div>
                </div>
                </div>
    
    </div>
  )
}

export default AdressBook