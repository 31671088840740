import { XCircleIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import HeaderBar from '../../components/HeaderBar'

const New = () => {

    const [msg, setMsg] = useState("")
    const [vorname, setVorname] = useState("")
    const [nachname, setNachname] = useState("")
    const [strasse, setStrasse] = useState("")
    const [plz, setPlz] = useState("")
    const [email, setEmail] = useState("")
    const [telefon, setTelefon] = useState("")
    const [anmerkung, setAnmerkung] = useState("")
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const CreateCustomer = async(e) =>{
        setLoading(true)
            e.preventDefault()        
        
    try {
        await axios.post(`https://meisen.waithere.de/api/new-customer`, {
            vorname:vorname,
            nachname:nachname,
            street:strasse,
            city:plz,
            email:email,
            telefon:telefon,
            anmerkung:anmerkung
        })
        .then(res => {
            setLoading(false)
            navigate('../')
          })
          
        
    } catch (error) {
        console.log(error)
            setLoading(false)
            setMsg(error.response.data);
        
    }

    }

    const forceCreateCustomer = async() =>{
        setLoading(true)
        
    try {
        await axios.post(`https://meisen.waithere.de/api/force-new-customer`, {
            vorname:vorname,
            nachname:nachname,
            street:strasse,
            city:plz,
            email:email,
            telefon:telefon,
            anmerkung:anmerkung
        })
        .then(res => {
            setLoading(false)
            navigate('../')
          })
          
        
    } catch (error) {
        console.log(error)
            setLoading(false)
            setMsg(error.response.data);
        
    }

    }

  return (
    <>
    <HeaderBar title="Kunde anlegen" />
      <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <p className="mt-2 text-sm text-gray-700">
            Legen Sie einen neuen Kunden an.
          </p>
        </div>
        
      </div>
      </div>
      <div className="mt-10">

        <form onSubmit={CreateCustomer}>
            <div className="overflow-hidden shadow sm:rounded-md">
            <div className="bg-white px-4 py-5 sm:p-6">
                {msg &&
                <div className="rounded-md bg-red-50 p-4 mb-2">
                <div className="flex">
                    <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">Fehler beim Anlegen des Kunden</h3>
                    <div className="mt-2 text-sm text-red-700">
                        <p>{msg}</p>
                    </div>
                    {msg.includes("existiert")?
                    <div className="mt-4">
                    <div className="-mx-2 -my-1.5 flex">
                      <button
                        type="button"
                        onClick={()=>forceCreateCustomer()}
                        className="rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                      >
                        Trotzdem hinzufügen
                      </button>
                      <button
                        type="button"
                        onClick={()=>{setMsg("");setLoading(false)}}
                        className="ml-3 rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                      >
                        Verwerfen
                      </button>
                    </div>
                    </div>:""

                    }
                    </div>
                </div>
                </div>
                }
                <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-6 sm:col-span-3 sm:col-start-1">
                    <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                        Vorname
                    </label>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="city"
                        id="city"
                        onChange={(e)=>setVorname(e.target.value)}
                        autoComplete="address-level2"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                        Nachname
                    </label>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="region"
                        id="region"
                        onChange={(e)=>setNachname(e.target.value)}
                        autoComplete="address-level1"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3 sm:col-start-1">
                    <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                        Email
                    </label>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="city"
                        id="city"
                        onChange={(e)=>setEmail(e.target.value)}
                        autoComplete="address-level2"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                        Telefon
                    </label>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="region"
                        id="region"
                        onChange={(e)=>setTelefon(e.target.value)}
                        autoComplete="address-level1"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3 sm:col-start-1">
                    <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                        Straße und Hausnummer
                    </label>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="city"
                        id="city"
                        onChange={(e)=>setStrasse(e.target.value)}
                        autoComplete="address-level2"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                        PLZ und Stadt
                    </label>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="region"
                        id="region"
                        onChange={(e)=>setPlz(e.target.value)}
                        autoComplete="address-level1"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    </div>

                    <div className="col-span-full">
                    <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                        Anmerkungen
                    </label>
                    <div className="mt-2">
                        <textarea
                        id="about"
                        name="about"
                        rows={3}
                        onChange={(e)=>setAnmerkung(e.target.value)}
                        className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                        defaultValue={''}
                        />
                    </div>
                    <p className="mt-3 text-sm leading-6 text-gray-600">Dieser Text erscheint in der Kundenkartei.</p>
                    </div>


                        </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                        <button
                            type="submit"
                            disabled={loading}
                            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        > {loading?
                            <span className='flex'><svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg> Lädt...</span>:
                            <span>Kunde anlegen</span>

                        }
                        </button>
                        </div>
                    </div>
                    </form>

                </div>
    </>
  )
}

export default New