import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Outlet, Routes, Route } from 'react-router-dom'
import App from './App';
import PrivateRoute from './helper/PrivateRoute';
import './index.css';
import axios from 'axios'
import Dashboard from './pages/Dashboard/Dashboard';
import Settings from './pages/Settings/Settings';
import NotFound from './pages/Notfound/NotFound';
import { AuthPage } from './pages/AuthPages/AuthPage';
import Create from './pages/Create/Create';
import Stock from './pages/Stock/Stock';
import Update from './pages/Update/Update';
import Customers from './pages/Customers/Customers';
import Pricing from './pages/Pricing/Pricing';
import Videos from './pages/Videos/Videos';
import Receipts from './pages/Receipts/Receipts';
import Statistics from './pages/Statistics/Statistics';
import { Role } from './helper/Roles';
import New from './pages/Customers/New';
import Register from './pages/AuthPages/Register';
import CreateNew from './pages/Create/CreateNew';
import EditStock from './pages/Stock/EditStock';
import ID from './pages/Settings/Inventur/ID';
import Beleg from './pages/Receipts/Detail/Beleg';

const html = document.getElementsByTagName("html")[0];
html.classList.add("bg-gray-100")
      

axios.defaults.withCredentials = true;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename='/app'>
                        <Routes>

                                <Route path="/" element={<PrivateRoute><App/></PrivateRoute>}>  
                                  <Route index element={<PrivateRoute><Create/></PrivateRoute>}/>
                                  <Route path="create">
                                  <Route index element={<PrivateRoute><Create/></PrivateRoute>}/>
                                  <Route path='new' element={<PrivateRoute role="Admin"><CreateNew/></PrivateRoute>}/>
                                  </Route>
                                  <Route path="stock">
                                  <Route index element={<PrivateRoute><Stock/></PrivateRoute>}/>
                                  <Route path='update' element={<PrivateRoute role="Admin"><EditStock/></PrivateRoute>}/>
                                  </Route>
                                  <Route path="update">
                                  <Route index element={<PrivateRoute><Update/></PrivateRoute>}/>
                                  </Route>
                                  <Route path="customers">
                                  <Route index element={<PrivateRoute><Customers/></PrivateRoute>}/>
                                  <Route path='new' element={<PrivateRoute><New/></PrivateRoute>}/>
                                  </Route>
                                  <Route path="pricing">
                                  <Route index element={<PrivateRoute><Pricing/></PrivateRoute>}/>
                                  </Route>
                                  <Route path="videos">
                                  <Route index element={<PrivateRoute><Videos/></PrivateRoute>}/>
                                  </Route>
                                  <Route path="receipts">
                                  <Route index element={<PrivateRoute role="Admin"><Receipts/></PrivateRoute>}/>
                                  <Route path='beleg'>
                                  <Route path=':id' element={<PrivateRoute role="Admin"><Beleg/></PrivateRoute>}/>
                                  </Route>
                                  </Route>
                                  <Route path="statistics">
                                  <Route index element={<PrivateRoute role="Admin"><Statistics/></PrivateRoute>}/>
                                  </Route>
                                  <Route path="settings">
                                  <Route index element={<PrivateRoute role="Admin"><Settings/></PrivateRoute>}/>
                                  <Route path='inventur'>
                                    <Route path=':id' element={<PrivateRoute role="Admin"><ID/></PrivateRoute>}/>
                                  </Route>
                                  </Route>
                                  </Route>
                                <Route path="*" element={<NotFound/>} />
                                <Route path="/login" element={<AuthPage/>} />
                                <Route path="/register" element={<Register/>} />
                             
                        </Routes>
    </BrowserRouter>
);

