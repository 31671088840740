import { ArrowPathIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'

const GlobalSpinner = () => {

const [refresh, setRefresh] = useState(false)

  useEffect(() => {
   setTimeout(() => {
    setRefresh(true)
   }, 5000);
  }, [])
  

  return (
    
    <div className="absolute inset-0 z-50 h-screen flex flex-col bg-white">
        <svg className="animate-spin m-auto h-16 w-16 text-gray-400 block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            {refresh &&
                            <button type="button" onClick={()=>{window.location.reload()}} className='font-bold text-xs text-indigo-600 m-auto'><span>Das lädt ungewöhnlich lange...</span> Neustarten <ArrowPathIcon className='w-5 h-5 text-indigo-600 inline-block'/></button>}
    </div>

  )
}

export default GlobalSpinner