import { BriefcaseIcon, CreditCardIcon, CurrencyEuroIcon, KeyIcon, UserCircleIcon, UserGroupIcon, UserPlusIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import HeaderBar from '../../components/HeaderBar'
import Inventur from './Inventur'
import Mitarbeiter from './Mitarbeiter'
import AdressBook from './AdressBook'
import { BookOpenIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const TabSwitcher = (props) => {

    switch (props.form) {
        case "Mitarbeiter":
          return <Mitarbeiter/>
          break;
        case "Inventur":
          return <Inventur/>
          break;
        case "Adressbuch":
          return <AdressBook/>
          break;  
        default:
            return (<div>Default</div>)
            break;
    }

}




export default function Settings(props) {

  const navigationList = [
    { name: 'Mitarbeiter', href: '#', icon: BriefcaseIcon, current: true },
    { name: 'Inventur', href: '#', icon: UserCircleIcon, current: false },
    { name: 'Adressbuch', href: '#', icon: BookOpenIcon, current: false },
  ]

    const [navigation, setNavigation] = useState(navigationList)
    const [currentForm, setCurrentForm] = useState("Mitarbeiter")
    
    
    const switchMenu = (key) => {
        setCurrentForm(key)
        setNavigation(
            navigation.map((item) => {
              if (item.name === key) {
                return { ...item, current: true }
              } else {
                return { ...item, current: false};
              }
            })
          );
    }

  return (
    <>
    <HeaderBar title="Erweiterte Funktionen" />
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="py-6 px-2 sm:px-6 lg:col-span-3 lg:py-0 lg:px-0">
        <nav className="space-y-1">
          {navigation.map((item) => (
            <button
              key={item.name}
              onClick={()=>switchMenu(item.name)}
              className={classNames(
                item.current
                  ? 'bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white'
                  : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                'group rounded-md px-3 py-2 flex items-center text-sm font-medium w-full'
              )}
              aria-current={item.current ? 'page' : undefined}
            >
              <item.icon
                className={classNames(
                  item.current
                    ? 'text-indigo-500 group-hover:text-indigo-500'
                    : 'text-gray-400 group-hover:text-gray-500',
                  'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                )}
                aria-hidden="true"
              />
              <span className="truncate">{item.name}</span>
            </button>
          ))}
        </nav>
      </aside>

      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <TabSwitcher role={props.role} form={currentForm}/>
      </div>
    </div>
    </>
  )
}