import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ArrowUpOnSquareStackIcon, UserIcon, MagnifyingGlassIcon, ArrowDownCircleIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export default function Popup(props) {

  const [kunde, setKunde] = useState({})
  const [vorname, setVorname] = useState("")
  const [nachname, setNachname] = useState("")
  const [strasse, setStrasse] = useState("")
  const [plz, setPlz] = useState("")
  const [email, setEmail] = useState("")
  const [telefon, setTelefon] = useState("")
  const [anmerkung, setAnmerkung] = useState("")
  const [id, setId] = useState(props.id)
  const [edit, setEdit] = useState(false)
  const [belege, setBelege] = useState([])
  const [pageSize, setPageSize] = useState(5)
  const [pageNumber, setPageNumber] = useState(1) 
  const [query, setQuery] = useState("")
  const [grouped, setGrouped] = useState()


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


  useEffect(() => {
    try{
      if(props.id){
        axios.get(`https://meisen.waithere.de/api/customer?id=${props.id ? props.id:id}`)
        .then(res =>{
            setKunde(res.data)
            setVorname(res.data.vorname || "")
            setNachname(res.data.nachname || "")
            setStrasse(res.data.strasse)
            setPlz(res.data.plz)
            setEmail(res.data.email)
            setTelefon(res.data.telefon)
            setAnmerkung(res.data.anmerkung)
        })
        axios.get(`https://meisen.waithere.de/api/belege?id=${props.id?props.id:id}`)
        .then(res=>{
          setBelege(res.data)
        })
      }
      

    }catch(error){
      console.log(error)
    }
  }, [props.id, edit])

  const cancelButtonRef = useRef(null)
  const navigate = useNavigate()
  const [msg, setMessage] = useState("Jetzt bestellen")
  const [btn, setButtonVisibility] = useState(true)
    

  const saveChanges = async() => {

    try{
      await axios.post(`https://meisen.waithere.de/api/customer`,{
            vorname:vorname,
            nachname:nachname,
            street:strasse,
            city:plz,
            email:email,
            telefon:telefon,
            anmerkung:anmerkung,
            id:props.id
      })
      .then(res =>{
          setKunde(res.data)
          setEdit(false)
      })

    }catch(error){
      console.log(error)
    }
  }


  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  const startDownload = (id) => {
    console.log(id)
  }
  

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={()=>{setPageNumber(1);props.onHide(); }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6 max-h-[90vh] overflow-y-auto">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <UserIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {!edit?
                      kunde?.vorname + " " + kunde?.nachname :  
                      <dd className='flex flex-col sm:flex-row justify-center'>
                          <div className="">
                          <input
                          type="text"
                          name="city"
                          id="city"
                          onChange={(e)=>setVorname(e.target.value)}
                          autoComplete="address-level2"
                          className="mx-auto rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={vorname}
                          />
                          </div>
                          <div className="">
                          <input
                          type="text"
                          name="city"
                          id="city"
                          onChange={(e)=>setNachname(e.target.value)}
                          autoComplete="address-level2"
                          className="mx-auto rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={nachname}
                          />
                          </div>
                          </dd>
                      }
                      <span className='block'>
                      {edit ?
                        <button 
                        onClick={()=>saveChanges()}
                        type="button" 
                        className="font-base text-xs text-indigo-600 hover:text-indigo-500">
                              Speichern
                            </button> : 
                        <button 
                        onClick={()=>setEdit(true)}
                        type="button" 
                        className="font-base text-xs text-indigo-600 hover:text-indigo-500">
                              Bearbeiten
                            </button>     
                        }
                            </span>
                    </Dialog.Title>
                    <div className="sm:col-span-12 md:col-span-7">
                      <dl className="grid grid-cols-1 gap-y-8 border-b border-gray-200 py-8 sm:grid-cols-2 sm:gap-x-6 sm:py-6 md:py-10">
                        <div>
                          <dt className="font-medium text-gray-900">Adresse</dt>
                          {!edit?
                          <dd className="mt-3 text-gray-500">
                            <span className="block">{kunde?.strasse}</span>
                            <span className="block">{kunde?.plz}</span>
                          </dd> :
                          <dd>
                          <div className="block">
                          <input
                          type="text"
                          name="city"
                          id="city"
                          onChange={(e)=>setStrasse(e.target.value)}
                          autoComplete="address-level2"
                          className="mx-auto block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={strasse}
                          />
                          </div>
                          <div className="block">
                          <input
                          type="text"
                          name="city"
                          id="city"
                          onChange={(e)=>setPlz(e.target.value)}
                          autoComplete="address-level2"
                          className="mx-auto block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={plz}
                          />
                          </div>
                          </dd>
                          }
                        </div>
                        <div>
                          <dt className="font-medium text-gray-900">Erreichbar unter</dt>
                          {!edit?
                          <dd className="mt-3 text-gray-500">
                            <span className="block">{kunde?.email}</span>
                            <span className="block">{kunde?.telefon}</span>
                          </dd> :
                          <dd>
                          <div className="block">
                          <input
                          type="text"
                          name="city"
                          id="city"
                          onChange={(e)=>setEmail(e.target.value)}
                          autoComplete="address-level2"
                          className="mx-auto block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={email}
                          />
                          </div>
                          <div className="block">
                          <input
                          type="text"
                          name="city"
                          id="city"
                          onChange={(e)=>setTelefon(e.target.value)}
                          autoComplete="address-level2"
                          className="mx-auto block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={telefon}
                          />
                          </div>
                          </dd>
                          }
                        </div>
                      </dl>
                      </div>
                    <div className="mt-2">
                      {!edit?
                      <p className="text-sm text-gray-500 whitespace-pre-wrap">
                        {kunde?.anmerkung}
                      </p>:
                      <div className="mt-2">
                      <textarea
                      id="about"
                      name="about"
                      rows={3}
                      onChange={(e)=>setAnmerkung(e.target.value)}
                      className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                      placeholder='Platz für Anmerkungen'
                      defaultValue={anmerkung}
                      />
                  </div>
                      }
                    </div>
                  </div>
                  <div className="mt-8 flow-root">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <div className="flex flex-1 items-center justify-start lg:justify-end bg-gray-50 p-2">
                                <div className="w-full max-w-lg lg:max-w-xs">
                                <label htmlFor="search" className="sr-only">
                                    Suchen
                                </label>
                                <div className="relative">
                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                    <input
                                    id="search"
                                    name="search"
                                    onChange={(e)=>{setQuery(e.target.value);setPageNumber(1)}}
                                    className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="Positionen durchsuchen"
                                    type="search"
                                    />
                                </div>
                                </div>
                            </div>
                          <table className="min-w-full divide-y divide-gray-300">
                            
                            <thead className="bg-gray-50">
                              <tr>
                                
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                  Datum
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Produkt
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Menge
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                  Gesamt
                                </th>
                                
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {/* {belege.filter(kunde=>kunde.product_id.toLowerCase().includes(query.toLowerCase()) || kunde.color_id.toLowerCase().includes(query.toLowerCase())).slice(pageNumber === 1 ? pageNumber -1 :pageNumber*pageSize-10+1, pageNumber * pageSize).map((person) => (
                                <tr key={person.id}>
                                    
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  <date>{new Date(person.date).toLocaleDateString('de-DE')}</date>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.product_id} {person.color_id}</td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-ellipsis overflow-hidden max-w-xs">{person.amount}</td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatter.format(person.total)}</td>
                                  
                                </tr>
                              ))} */}
                              {belege?.slice(pageNumber*pageSize- (5 + (pageNumber -1)), pageNumber * pageSize - (pageNumber)).map((location, index) => (
                              <Fragment key={index}>
                                <tr className="border-t border-gray-200">
                                  <th
                                    colSpan={4}
                                    scope="colgroup"
                                    className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                  >
                                    <span className='flex gap-2'>
                                    {new Date(location.date).toLocaleDateString('de-DE')}
                                    <button type='button' onClick={()=>startDownload(location.custom_id)}><ArrowDownCircleIcon className='w-5 h-6'/></button>
                                    </span>
                                  </th>
                                  
                                </tr>
                                {location.items.map((person, personIdx) => (
                                  <tr
                                    key={person.id}
                                    className={classNames(personIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                                  >
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.product_id} {person.color_id} {person.addon === "none"? "":person.addon }</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.amount}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatter.format(person.total)}</td>
                                    
                                  </tr>
                                ))}
                              </Fragment>
                            ))}
                            </tbody>
                            
                          </table>
                          <nav
                            className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                            aria-label="Pagination"
                            >
                            <div className="hidden sm:block">
                                <p className="text-sm text-gray-700">
                                Zeige Eintrag <span className="font-medium">{pageNumber === 1 ? pageNumber:pageNumber*pageSize-5}</span> bis <span className="font-medium">{pageSize * pageNumber-1}</span> von{' '}
                                <span className="font-medium">{belege.length}</span> Einträgen
                                </p>
                            </div>
                            <div className="flex flex-1 justify-between sm:justify-end">
                                <button
                                type='button'
                                onClick={()=>{setPageNumber(pageNumber <= 1 ? 1:pageNumber -1)}}
                                className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                                >
                                Vorherige
                                </button>
                                <button
                                onClick={()=>{setPageNumber(pageNumber +1 )}}
                                type='button'
                                className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                                >
                                Nächste
                                </button>
                            </div>
                            </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {btn &&
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3">
                  
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={()=>{setPageNumber(1);props.onHide(); }}
                    ref={cancelButtonRef}
                  >
                    Schließen
                  </button>
                </div>
                }
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}