import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar'
import { BugContext } from './components/context';
import axios from 'axios';


function App(props) {

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const [data, setData] = useState([])

const sendMail = async(data) =>{
  
  try{
    await axios.post(`https://meisen.waithere.de/api/bugreport`, {data})
    .then(e=>{
      dispatchBugEvent('SUCCESS', {response:"Versendet"})
    }).catch(e=>{
      dispatchBugEvent('ERROR', {response:"Fehler beim senden"})
    })

  }catch(error){
      console.log(error)
  }
}  

const dispatchBugEvent = (actionType, payload) => {
  switch (actionType) {
    case 'REPORT':
      setData([ ...data, payload ]);
      return;
    case 'REMOVE':
      setData(data.filter(e => e.url !== payload.url));
      return; 
    case 'RESET':
      setData([]);
      return; 
    case 'SUCCESS':
      setData([payload]);
      return;  
    case 'ERROR':
      setData([ ...data, payload ]);
      return;     
    case 'SEND':
      sendMail(data)
      // dispatchBugEvent('RESET')
      return;    
    default:
      return;
  }
};

  return (
    <BugContext.Provider value={{data, dispatchBugEvent}}>

      <div>
        <Sidebar api={props} sidebarOpen={sidebarOpen} setSidebarOpen={(event)=>{setSidebarOpen(event)}}/>

        <div className="md:pl-64">
          <div className="mx-auto flex max-w-7xl flex-col md:px-8 max-xl:px-0">

           <Navbar setSidebarOpen={(event)=>{setSidebarOpen(event)}}/> 

            <main className="flex-1">
              <div className="py-6">
                <div className="px-4 sm:px-6 md:px-0">
                  <Outlet/>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
      </BugContext.Provider>

    );
}

export default App;
