import { EllipsisVerticalIcon, InformationCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import HeaderBar from '../../components/HeaderBar'
import { Menu, Transition } from '@headlessui/react'


export const Table = (props) => {

    const [data, setData] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [pageNumber, setPageNumber] = useState(1) 
    const [query, setQuery] = useState("")
    const [refresh, setRefresh] = useState(false)

    const dictionary = {
      'Bürste': 'brush',
      'bürste': 'brush',
      'bü': 'brush',
      'bür': 'brush',
      'bürs': 'brush',
      'bürst': 'brush',
    };

    function translate(text, dictionary) {
      return dictionary[text] || text; // Returns the original text if no translation is found
    }

    let searchArray = translate(query.toLowerCase(), dictionary).trim().split(" ");

    console.log(props.auth.role)

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }  

    const deleteEntry = (id)=>{
        axios.post(`https://meisen.waithere.de/api/delete-stock`,{
          id: id
        })
        .then(()=>{
            setRefresh(!refresh)
        })
        .catch(()=>{

        })
    }

    useEffect(() => {
        try{
          axios.get(`https://meisen.waithere.de/api/stock?location=${props?.location}`)
          .then((res)=>{
              setData(res.data.stock)
          })
        }catch(error){
          console.log(error)
        }
      }, [props.location, refresh])


  return (
    <div className="mt-8 flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <div className="flex flex-1 items-center justify-start lg:justify-end bg-gray-50 p-2">
                    <div className="w-full max-w-lg lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">
                        Suchen
                    </label>
                    <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                        id="search"
                        name="search"
                        onChange={(e)=>{setQuery(e.target.value);setPageNumber(1)}}
                        className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Bestand durchsuchen"
                        type="search"
                        />
                    </div>
                    </div>
                </div>
              <table className="min-w-full divide-y divide-gray-300">
                
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Produkt
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Farbe
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Bestand
                    </th>    
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data?.filter(kunde=> searchArray.every(el=> (kunde.product.name + " " + kunde.color.name).toLowerCase().includes(el)))
                    .slice(pageNumber === 1 ? 0 :pageNumber*pageSize-10, pageNumber * pageSize)
                    .map((person) => (
                    <tr key={person.id} className={classNames(person.available <= 0? "bg-red-200":"")}>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        {props.auth.role === "Admin" &&
                        <Menu as="div" className="relative flex-none">
                          <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute left-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                             
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    type='button'
                                    onClick={()=>{deleteEntry(person.id)}}
                                    className={classNames(
                                      active ? 'bg-gray-50' : '',
                                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                                    )}
                                  >
                                    Löschen
                                  </button>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>}
                      </td>  
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {person.product.name} <span className='italic text-gray-500'>{person.category_id === 6 ? "Reserviert für RM":""}</span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.color.name}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 text-ellipsis overflow-hidden max-w-xs font-medium">{person.available}</td>
                      
                      
                    </tr>
                  ))}
                </tbody>
                
              </table>
              <nav
                className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination"
                >
                <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                    Zeige Eintrag <span className="font-medium">{pageNumber === 1 ? pageNumber:pageNumber*pageSize-10+1}</span> bis <span className="font-medium">{pageSize * pageNumber}</span> von{' '}
                    <span className="font-medium">{data.length}</span> Produkten
                    </p>
                </div>
                <div className="flex flex-1 justify-between sm:justify-end">
                    <button
                    type='button'
                    onClick={()=>{setPageNumber(pageNumber===1?1:pageNumber -1)}}
                    className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                    >
                    Vorherige
                    </button>
                    <button
                    onClick={()=>{setPageNumber(pageNumber +1 )}}
                    type='button'
                    className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                    >
                    Nächste
                    </button>
                </div>
                </nav>
            </div>
          </div>
        </div>
      </div>
  )
}


function Stock(props) {

  console.log(props)
    const [tabList, setTabList] = useState([])
    const [currentForm, setCurrentForm] = useState("")
    const [currentLocation, setCurrentLocation] = useState("")

    useEffect(() => {
        try{
          axios.get("https://meisen.waithere.de/api/locations")
          .then((res)=>{
              setTabList(res.data)
              setCurrentForm(res.data[0].name)
              setCurrentLocation(res.data[0].id)
          })
        }catch(error){
          console.log(error)
        }
      }, [])
    function classNames(...classes) {
      return classes.filter(Boolean).join(' ')
    }

    const switchMenu = (key, id) => {
        setCurrentForm(key)
        setCurrentLocation(id)
    }
    

  return (
    <>
    <HeaderBar title="Bestandslisten" />
    
    <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <p className="mt-2 text-sm text-gray-700">
            Übersicht über Ihre Bestandslisten.
          </p>
        </div>
        { props.role === "Admin" &&
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <NavLink
            to="update"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Bestand aktualisieren
          </NavLink>
        </div>}
      </div>
    <main className="flex-1">
              <div className="relative px-4 lg:px-0">                  
                  <div className="">
                      {/* Tabs */}
                      <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                        Select a tab
                        </label>
                        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                        <select
                        id="tabs"
                        name="tabs"
                        className="mt-4 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-purple-500"
                        defaultValue="Bitte wählen"
                        onChange={(e)=>switchMenu(e.target.value, e.target.key)}
                        >
                        {tabList.map((tab) => (
                            <option key={tab.id} value={tab.name}>{tab.name}</option>
                        ))}
                        </select>
                    </div>
                      <div className="hidden sm:block">
                        <div className="border-b border-gray-200">
                          <nav className="-mb-px flex space-x-8">
                            {tabList.map((tab) => (
                              <button
                                key={tab.id}
                                type='button'
                                onClick={()=>switchMenu(tab.name, tab.id)}
                                className={classNames(
                                  tab.name === currentForm
                                    ? 'border-purple-500 text-purple-600'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                )}
                              >
                                {tab.name}
                              </button>
                            ))}
                          </nav>
                        </div>
                      </div>
                      <div className="mt-10 divide-y divide-gray-200">
                        <div className="space-y-1">
                             {currentLocation && <Table auth={props} location={currentLocation}/>}
                         </div>
                      </div>
                      
                  </div>
              </div>
            </main>
    </>

  )
}

export default Stock