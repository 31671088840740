
import { LockClosedIcon } from '@heroicons/react/20/solid'
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { CheckCircleIcon, CheckIcon, ChevronUpDownIcon, PlusIcon } from '@heroicons/react/24/outline';

export default function Register() {

    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [cName, setCName] = useState('');
    const [people, setPeople] = useState([]);
    const [location, setLocation] = useState("")
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [msg, setMsg] = useState('');
    const [passwdMsg, setPasswdMsg] = useState('Bitte Passwort erneut eingeben')
    const [btn, setBtn] = useState(false)
    const [consent, setConsent] = useState(false)
    let navigate = useNavigate();
    const [query, setQuery] = useState('')
    const [selectedPerson, setSelectedPerson] = useState(null)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
      
      try{
        axios.get("https://meisen.waithere.de/api/locations")
        .then((res)=>{
            setPeople(res.data)
        })
      }catch(error){
        console.log(error)
      }
    
    }, [])
    

    const checkPassword = (e) => {
        if (password !== e) {
            setBtn(false)
            setPasswdMsg("Passwörter stimmen nicht überein")
        } else{
            setBtn(true)
            setPasswdMsg("Das sieht gut aus")
        }
  }
    const newLocation = async() => {
        try{

          await axios.post('https://meisen.waithere.de/api/new-location', {location: location})
          .then((res)=>{
            setPeople(res.data)
            setSuccess(true)
            setLocation("")
          })

        }catch(error){
          console.log(error)

        }
    }

    const filteredPeople =
    query === ''
      ? people
      : people.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase())
        })
 
    const Register = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`https://meisen.waithere.de/api/register`, {
                cname: cName,
                email: email,
                location: location,
                password: password,
                confPassword: confPassword,
            });
            navigate("/login");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
    function classNames(...classes) {
      return classes.filter(Boolean).join(' ')
    }

    const html = document.getElementsByTagName("html")[0];
    useEffect(() => {
      html.classList.remove("bg-gray-100")
      html.classList.add("bg-white")
    }, [])


  

console.log(query)
  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="https://meisen.waithere.de/dashboard/RonnieMeisenLogoOnline.png"
              alt="Ronnie Meisen"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Einen neuen Benutzer registrieren
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Bereits registriert?{' '}
              <Link to={"/login"} className="font-medium text-indigo-600 hover:text-indigo-500">
                Jetzt anmelden
              </Link>
            </p>
          </div>
          <form className="mt-8 space-y-6" onSubmit={Register}>
            <p className="has-text-centered">{msg}</p>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="company-name" className="block text-sm font-medium text-gray-700">
                        Benutzername
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="company-name"
                          id="company-name"
                          required
                          className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          placeholder="Name des Angestellten"
                          value={cName} onChange={(e) => setCName(e.target.value)}
                        />
                        
                      </div>
                    </div>


                    <Combobox as="div" value={selectedPerson} onChange={setSelectedPerson}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-700">Standort auswählen</Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(person) => person?.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredPeople.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.map((person) => (
              <Combobox.Option
                key={person.id}
                value={person}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{person.name}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>

    <div>
      <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-700">
        Standort nicht dabei? Jetzt hinzufügen.
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">

        <input
          type="text"
          name="location"
          id="location"
          className="block w-full rounded-md border-0 py-1.5 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Neuer Standortname"
          defaultValue={location}
          onChange={(e)=>setLocation(e.target.value)}
        />
        <div className="absolute justify-center inset-y-0 right-0 flex items-center">
          <label htmlFor="add" className="sr-only">
            Hinzufügen
          </label>
          <button
          type="button"
          onClick={()=>newLocation()}
          className="h-full rounded-md border-0 bg-transparent py-0 pr-4 pl-4 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm select-none sele"
          >
            {success ? <CheckCircleIcon className='h-6 w-6'/> : <PlusIcon className='h-6 w-6'/> }
          </button>
        </div>
      </div>
    </div>

                       
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 mb-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Email"
                  value={email} onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Passwort
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Passwort"
                  value={password} onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Passwort bestätigen
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Passwort bestätigen"
                  value={confPassword} onChange={(e) => {setConfPassword(e.target.value); checkPassword(e.target.value)}}
                />
                
              </div>
            </div>
            <p className="mt-2 text-sm text-gray-500">{passwdMsg}</p>
            

            <div>
              <button
                type="submit"
                disabled={!btn}
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-300"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span>
                Registrieren
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
