import React from 'react'

const NotFound = () => {
  return (
    <div>
        <h1>Seite nicht gefunden</h1>
        <a href='/'>Zurück zum Dashboard</a>
    </div>
  )
}

export default NotFound