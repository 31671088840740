import { ArrowPathIcon, XCircleIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import HeaderBar from '../../components/HeaderBar'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"


const addons = [
  {id:1, name:"Entfernen Fremdextensions",price:105},
  {id:2, name:"Hairwear Befestigung",price:105},
  {id:3, name:"Arbeitsaufwand 1. Reihe +", price:125},
  {id:4, name:"Arbeitsaufwand 2. Reihe +", price:75},
  {id:5, name:"Arbeitsaufwand 3. Reihe +", price:75},
  {id:6, name:"Arbeitsaufwand 4. Reihe +", price:50},
]

function Pricing(props) {


    const [products, setProducts] = useState([])
    const [edit, setEdit] = useState()
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [newPrice, setNewPrice] = useState("")
    const [reload, setReload] = useState(false)
    let hash = Object.create(null)
    const [result, setResult] = useState([])

    useEffect(() => {
        try{
          axios.get("https://meisen.waithere.de/api/pricing")
          .then((res)=>{

              setProducts(res.data)

          })
  
        }catch(error){
              console.log(error)
        }
        return () => {
          // Cleanup function to clear data
          setProducts([]);
        };
      }, [reload])
  
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });

      const deleteProduct = (id) => {
            try{

                axios.put("https://meisen.waithere.de/api/delete-product",{
                    product_id: id
                })
                .then((res)=>{
                    setReload(!reload)
                })

            }catch(error){
                console.log(error)
            }
      }

      const updateProduct = (id) => {
        
        try{

            axios.put("https://meisen.waithere.de/api/update-product",{
                product_id: id,
                price: newPrice.replace(",",".")
            })
            .then((res)=>{
                setNewPrice("")
                setReload(!reload)
            })

        }catch(error){
            console.log(error)
        }
  }

      const setEditMode = (id) => {

            if(id === edit){
                setEdit()
                setSelectedProduct(null)
                console.log("speichern hier")
            } else{
                setEdit(id)
                console.log("edit mode on")
            }
            
      }


  return (
    <>
    <HeaderBar title="Preisliste" />
    
    <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <p className="mt-2 text-sm text-gray-700">
            Verwalten, bearbeiten und ergänzen Sie Ihre Preislisten.
          </p>
        </div>
        { props.role === "Admin" &&
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <NavLink
            to="../create/new"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Produkt anlegen
          </NavLink>
        </div>}
      </div>
      {/* <div className='grid grid-cols-6 gap-6'> */}
      <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 900: 2, 1200: 3}} className='mt-8'>
        <Masonry gutter='30px'>
    {products?.filter(e=>e.category_name !== "RM Reserviert").map((category, index) => (
        
      <div className=" flow-root col-span-6 sm:col-span-3 lg:col-span-2">
        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <div className="sm:flex sm:items-center py-3.5 p-4 sm:p-6">
                    <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">{category.category_name}</h1>
                    
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        type="button"
                        onClick={()=>{setEditMode(category.category_id)}}
                        className="rounded bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                    >
                        {edit === category.category_id?
                        "Speichern":"Bearbeiten"}
                    </button>
                    </div>
                </div>
              <table className="min-w-full divide-y divide-gray-300">
                
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Artikel
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Preis
                    </th>
                    
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white striped">
                  {category.items.map((product, personIdx) => (
                    <tr key={product.id}>
                      
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {product.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        
                        {edit === category.category_id && selectedProduct === product.id?
                        <span className=" inline-flex rounded-md shadow-sm">
                        <input type="text" className='block w-full rounded-l-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' value={newPrice} placeholder={product.price} onChange={(e)=>setNewPrice(e.target.value)}/>
                        <button
                          type="button"
                          onClick={()=>updateProduct(product.id)}
                          className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                        >
                          <ArrowPathIcon className="-ml-0.5 h-5 w-5 text-green-400" aria-hidden="true" />
                        </button></span>
                        : edit === category.category_id ? <span className=" inline-flex rounded-md shadow-sm">
                            
                        
                        <button
                          type="button"
                          onClick={()=>setSelectedProduct(product.id)}
                          className="relative inline-flex items-center gap-x-1.5 rounded-l-md bg-white px-3 py-1 text-sm font-normal text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                        >
                          {formatter.format(product.price)} Ändern
                        </button>
                        <button
                          type="button"
                          onClick={()=>{deleteProduct(product.id)}}
                          className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                        >
                          <XCircleIcon className="-ml-0.5 h-5 w-5 text-red-400" aria-hidden="true" />
                        </button>
                      </span>:formatter.format(product.price)
                        }
                        
                      </td>
                    </tr>
                  ))}
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      ))}
        
        <div className=" flow-root col-span-6 sm:col-span-3 lg:col-span-2">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <div className="sm:flex sm:items-center py-3.5 p-4 sm:p-6">
                      <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-gray-900">Zusätze & Extras</h1>
                      
                      </div>
                     
                  </div>
                <table className="min-w-full divide-y divide-gray-300">
                  
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Leistung
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Preis
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white striped">
                  {addons.map((product, personIdx) => (
                  <tr key={personIdx}>
                      
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {product.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        
                        {formatter.format(product.price)}
                        
                      </td>
                    </tr>))}
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        </Masonry>
        </ResponsiveMasonry>
      {/* </div> */}
      
    
    </>
  )
}

export default Pricing