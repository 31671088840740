import { PencilIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { Link, redirect, useNavigate } from 'react-router-dom'

const HeaderBar = (props) => {

  const navigate = useNavigate()

  const path = window.location.pathname
  const goBack = () => {
    
    const pathname = window.location.href
    const parent = pathname.split("/").slice(-3, -props.back).join("/");
    console.log(parent)
    // navigate(parent)
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
    
  return (
    <div className="lg:flex lg:items-center lg:justify-between mb-4 md:mb-8">
        {path === "/app/create" &&
        <div className='absolute inset-0 -z-10 h-96 w-full bg-indigo-600 '>
        <img className="absolute inset-0 -z-10 h-96 w-full object-cover opacity-70" src="https://duesseldorf-haarverlaengerung.de/images/Meisen_2.jpg"/>
        </div>
        }
      <div className="min-w-0 flex-1">
            
            <button type='button' onClick={()=>navigate(-1)} className={classNames("text-sm font-medium block", path === "/app/create" ? "text-white hover:text-gray-300" : "text-indigo-600 hover:text-indigo-500")}>
              <span aria-hidden="true"> &larr;</span>
              Zurück
            </button>
        <h2 className={classNames("text-2xl font-bold leading-7  sm:truncate sm:text-3xl sm:tracking-tight", path === "/app/create" ? "text-white": "text-gray-900")}>
          {props.title}
        </h2>
      </div>
      {props.btnText &&
      <div className="mt-5 flex lg:mt-0 lg:ml-4">
      <span className="hidden sm:block">
        <Link
          to={props.action}
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500 text-white" aria-hidden="true" />
          {props.btnText}
        </Link>
      </span>        
    </div>
      }
      
    </div>
  )
}

export default HeaderBar