import React from 'react'
import { Link } from 'react-router-dom'
import HeaderBar from '../../components/HeaderBar'
import Thumbnail from '../../helper/Thumbnail'

function Videos() {
    const files = [
        {
          title: 'YouYou Haartressen',
          source:
            'https://meisen.waithere.de/dashboard/files/YouYouHaartressen.mp4',
        },
        {
            title: 'Tapes Application Training',
            source:
              'https://meisen.waithere.de/dashboard/files/TapesApplicationTraining.mp4',
          },
          {
            title: 'Weft Application Training',
            source:
              'https://meisen.waithere.de/dashboard/files/WeftApplicationTraining.mp4',
          },
          {
            title: 'YouYou Tape-Ins Entfernung',
            source:
              'https://meisen.waithere.de/dashboard/files/YOUYOUTape-InsEntfernung.mp4',
          },
          {
            title: 'Beyond Hair Expectations',
            source:'www.youtube.com/watch?v=AqwjPmPjZZY'
          }
      ]
  return (
    <>
    <HeaderBar title="Schulungsvideos" />
      <div className="lg:px-0">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <p className="mt-2 text-sm text-gray-700">
            Aktuelle Schulungsvideos zu verschieden Haarprodukten.
          </p>
        </div>
        
        
      </div>
      </div>
      <div className="mt-10">
        <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {files.map((file) => (
            <li key={file.source} className="relative">
            <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                {/* <img src={file.source} alt="" preload='metadata' className="pointer-events-none object-cover group-hover:opacity-75" /> */}
                <Thumbnail videoSrc={file.source} time={50}/>
                <button type="button" onClick={()=>window.open(file.source)} className="absolute inset-0 focus:outline-none">
                <span className="sr-only">View details for {file.title}</span>
                </button>
            </div>
            <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.title}</p>
            </li>
        ))}
        </ul>
      </div>
    </>
  )
}

export default Videos