import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const Beleg = () => {

    const {id} = useParams()
    const [inventur, setInventur] = useState({})
    const navigate = useNavigate()

    useEffect(() => {
        axios.get(`https://meisen.waithere.de/api/beleg?id=${id}`)
            .then((res)=>{
                setInventur(res.data[0])
            })
            return () => {
              // Cleanup function to clear data
              setInventur([]);
            };
      }, [])

      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });

      const html = document.getElementsByTagName("html")[0];
        useEffect(() => {
            html.classList.remove("bg-gray-100")
            html.classList.add("bg-white")
            return () => {
                html.classList.remove("bg-white")
                html.classList.add("bg-gray-100")
              }
        }, [])

        function downloadBlobFile(blob, fileName) {
          const isIE = !!document.documentMode;
          if (isIE) {
              window.navigator.msSaveBlob(blob, fileName);
          } else {
              let blob2 = new Blob( [blob], { type: "application/pdf" });
              const link = URL.createObjectURL(blob2);
              const a = document.createElement("a");
              a.setAttribute("download", fileName);
              a.setAttribute("href", link);
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
          }
      }

        function debugBase64(base64URL){
          var win = window.open();
          
          setTimeout(() => {
            win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
          });
        }

        const startDownload = ()=>{
            try{
            axios.get(`https://meisen.waithere.de/api/download-beleg?id=${id}`)
                    .then((res)=>{
                      const ua = navigator.userAgent
                      // if ((/iPad|iPhone|iPod/.test(ua))
                      //   || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)){
                      //     fetch(res.data)
                      //     .then(res=>res.arrayBuffer())
                      //     .then(data=>{
                      //       console.log(data)
                      //       downloadBlobFile(data, "beleg.pdf")})
                          
                      // } else {
                      //   debugBase64(res.data);
                      // }
                      fetch(res.data)
                          .then(res=>res.arrayBuffer())
                          .then(data=>{
                            console.log(data)
                            downloadBlobFile(data, "beleg.pdf")})
                    })

            }catch(error){
                console.log(error)
            }
        }

  return (
    inventur ? Object.keys(inventur).length !== 0 ?
    <div className="px-4 sm:px-6 lg:px-8 .print-content">
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
      <button type='button' onClick={()=>navigate(-1)} className=" text-sm font-medium text-indigo-600 hover:text-indigo-500 block">
              <span aria-hidden="true"> &larr;</span>
              Zurück
            </button>
        <h1 className="text-xl font-semibold text-gray-900">Beleg für {inventur.customer_name}</h1>
        <p className="mt-2 text-sm text-gray-700">
          Erstellt von {inventur.employee} am<time dateTime={inventur.custom_date?inventur.custom_date:inventur.date}> {inventur.custom_date?inventur.custom_date:new Date(inventur.date).toLocaleDateString('de-DE')}</time>.
        </p>
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button
          onClick={()=>startDownload()}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto print-hidden"
        >
          Herunterladen
        </button>
        
      </div>
    </div>
    <div className="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
            >
              Produkt
            </th>
            <th
              scope="col"
              className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Menge
            </th>
            <th
              scope="col"
              className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Preis
            </th>
            <th
              scope="col"
              className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
            >
              Gesamt
            </th>
          </tr>
        </thead>
        <tbody>
            {inventur.items.map((project) => {

            return <tr key={project.id} className="border-b border-gray-200">
              <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                <div className="font-medium text-gray-900">{project.product_id} Farbe: {project.color_id} {project.addon !== "none"?project.addon:""}</div>
                
              </td>
              <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{project.amount}</td>
              <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{formatter.format(project.price)}</td>
              <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">{formatter.format(project.total)}</td>
            </tr>
              })}
              {
                inventur.aufwand > 0? 
                <tr key="aufwand" className="border-b border-gray-200">
              <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                <div className="font-medium text-gray-900">Zusätzlicher Aufwand</div>
                
              </td>
              <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">1</td>
              <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{formatter.format(inventur.aufwand)}</td>
              <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">{formatter.format(inventur.aufwand)}</td>
              </tr>:""
              }
              
          
        </tbody>
        <tfoot>
          <tr>
            <th
              scope="row"
              colSpan={3}
              className="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
            >
              Stückzahl Gesamt
            </th>
            <th scope="row" className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden">
            Stückzahl Gesamt
            </th>
            <td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">{(inventur.items.reduce(function (acc, obj) { return acc + parseInt(obj.amount); }, 0))}</td>
          </tr>
          
          <tr>
            <th
              scope="row"
              colSpan={3}
              className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
            >
              Wert Gesamt
            </th>
            <th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">
              Wert Gesamt
            </th>
            <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
              {formatter.format((inventur.items.reduce(function (acc, obj) { return acc + parseFloat(obj.total); }, 0))+inventur.aufwand)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>:<></>:<><div>Kein Beleg unter dieser Nummer gefunden.</div></>
  )
}

export default Beleg