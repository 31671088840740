import React, { useRef, useEffect } from 'react';

function Thumbnail(props) {
  const videoRef = useRef();
  const canvasRef = useRef();
  const thumbnailRef = useRef();

  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const thumbnail = thumbnailRef.current;

    video.addEventListener('loadedmetadata', function() {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
    });

    video.addEventListener('canplay', function() {
        video.currentTime = props.time;
      });

    video.addEventListener('timeupdate', function() {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      thumbnail.src = canvas.toDataURL();
    });
  }, [props.videoSrc, props.time]);

  return (
    <>
        <video ref={videoRef} preload='metadata' muted crossorigin="anonymous">
        <source src={props.videoSrc} type="video/mp4" />
        </video>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      <img ref={thumbnailRef} alt="Thumbnail" />
    </>
  );
}

export default Thumbnail;