import { ArrowTopRightOnSquareIcon, EyeIcon, InformationCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import HeaderBar from '../../components/HeaderBar'
import Popup from '../../components/Popup'
import { MinusCircleIcon } from '@heroicons/react/20/solid'
import DeletePopup from '../../components/DeletePopup'

function Receipts() {

    const [kunden, setKunden] = useState([])
    const [kundenPaged, setKundenPaged] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [pageNumber, setPageNumber] = useState(1) 
    const [query, setQuery] = useState("")
    const [product, setProduct] = useState({});
    const [modal, setModalShow] = useState(false)
    const [belegId, setBelegId] = useState("")
    const [updated, setUpdated] = useState(false)

    useEffect(() => {
      try{
        axios.get(`https://meisen.waithere.de/api/belege?page=${pageNumber-1}&query=${query}`)
        .then((res)=>{
            setKunden(res.data)
        })

      }catch(error){
            console.log(error)
      }

      return () => {
        // Cleanup function to clear data
        setKunden([]);
      };
    
    }, [pageNumber, query, updated])

    // .filter(kunde=>kunde.customer_name.toLowerCase().includes(query.toLowerCase()) || kunde.date.toLowerCase().includes(query.toLowerCase())).slice(pageNumber === 1 ? pageNumber -1 :pageNumber*pageSize-10+1, pageNumber * pageSize)

    function parseDate(input) {
      var parts = input.match(/(\d+)/g);
      // note parts[1]-1
      return new Date(parts[2], parts[1]-1, parts[0]);
    } 


    const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
    
  return (
    <>
    <HeaderBar title="Belegübersicht"/>

    <div className="lg:px-0">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <p className="mt-2 text-sm text-gray-700">
            Übersicht aller erstellten Belege.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <NavLink
            to="../create"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Beleg erstellen
          </NavLink>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg ">
                <div className="flex flex-1 items-center justify-start lg:justify-end bg-gray-50 p-2">
                    <div className="w-full max-w-lg lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">
                        Suchen
                    </label>
                    <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input
                        id="search"
                        name="search"
                        onChange={(e)=>{setQuery(e.target.value);setPageNumber(1)}}
                        className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Kunden durchsuchen"
                        type="search"
                        />
                    </div>
                    </div>
                </div>
              <table className="min-w-full divide-y divide-gray-300">
                
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Optionen</span>
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Datum
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Ausgestellt von
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Kunde
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Stichworte
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Gesamt
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      
                    </th>
                    
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {kunden.filter(e=>e.custom_id.length > 4).sort(function compare(a,b){if((a.custom_date && b.custom_date)) return new Date((parseDate(b.custom_date)))- new Date(parseDate(a.custom_date))})
                  .slice(pageNumber === 1 ? 0 :pageNumber*pageSize-10, pageNumber * pageSize)
                  .map((person) => (
                    <tr key={person.id}>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <NavLink to={`beleg/${person.custom_id}`} className="text-indigo-600 hover:text-indigo-900">
                          <InformationCircleIcon className='w-5 h-5 text-gray-500'/><span className="sr-only">{person.custom_id}</span>
                        </NavLink>
                      </td>  
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {person.custom_date ? person.custom_date: new Date(person.date).toLocaleDateString('de-DE',{ year: 'numeric', month: '2-digit', day: '2-digit' })}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.employee}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.customer_name}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-ellipsis overflow-hidden max-w-xs">
                        {person.addons.filter(function(e){return e}).filter((value, index, array) => array.indexOf(value) === index).filter(word => word !== "none").map((item, index)=>{
                            return <span key={index} className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                            {item}
                          </span>
                        })}
                        {person.gift.filter(function(e){return e}).filter((value, index, array) => array.indexOf(value) === index).filter(word => word !== "none").map((item, index)=>{
                            return <span key={index} className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
                            {item}
                          </span>
                        })}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatter.format(person.gesamt.reduce((partialSum, a) => partialSum + a, 0) + person.aufwand)}</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        {person.custom_id.length <=4?
                        "alter Beleg":
                        <button type="button" onClick={()=>{setBelegId(person.custom_id);setModalShow(true);}} className="text-indigo-600 hover:text-indigo-900">
                          <MinusCircleIcon className='w-5 h-5 text-gray-500'/><span className="sr-only">Zurückbuchen</span>
                        </button>}
                      </td> 
                    </tr>
                  ))}
                </tbody>
                
              </table>
              <nav
                className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination"
                >
                <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                    Zeige Eintrag <span className="font-medium">{pageNumber === 1 ? pageNumber:pageNumber*pageSize-10+1}</span> bis <span className="font-medium">{pageSize * pageNumber}</span> von{' '}
                    <span className="font-medium">{kunden.filter(e=>e.custom_id>4).length}</span> Kunden
                    </p>
                </div>
                <div className="flex flex-1 justify-between sm:justify-end">
                    <button
                    type='button'
                    onClick={()=>{setPageNumber(pageNumber === 1 ? 1:pageNumber -1)}}
                    className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                    >
                    Vorherige
                    </button>
                    <button
                    onClick={()=>{setPageNumber(pageNumber +1 )}}
                    type='button'
                    className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                    >
                    Nächste
                    </button>
                </div>
                </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeletePopup 
        open={modal}
        onHide={()=>{setModalShow(false)}}
        id={belegId}
        updated={()=>{setUpdated(!updated)}}
        />  
    </>
  )
}

export default Receipts