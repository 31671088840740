import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const ID = () => {

    const {id} = useParams()
    const [inventur, setInventur] = useState({})

    useEffect(() => {
        axios.get(`https://meisen.waithere.de/api/inventur?id=${id}`)
            .then((res)=>{
                setInventur(res.data[0])
            })
        
      }, [])

      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });

      const html = document.getElementsByTagName("html")[0];
        useEffect(() => {
            html.classList.remove("bg-gray-100")
            html.classList.add("bg-white")
            return () => {
                html.classList.remove("bg-white")
                html.classList.add("bg-gray-100")
              }
        }, [])
        

return (
    Object.keys(inventur).length !== 0 ?
    <div className="px-4 sm:px-6 lg:px-8 .print-content">
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">Inventur für {inventur.stock_name}</h1>
        <p className="mt-2 text-sm text-gray-700">
          Erstellt am<time dateTime={inventur.date}> {new Date(inventur.date).toLocaleDateString('de-DE')}</time>.
        </p>
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button
          onClick={()=>window.print()}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto print-hidden"
        >
          Drucken
        </button>
      </div>
    </div>
    <div className="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
            >
              Produkt
            </th>
            <th
              scope="col"
              className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Menge
            </th>
            <th
              scope="col"
              className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Preis
            </th>
            <th
              scope="col"
              className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
            >
              Gesamt
            </th>
          </tr>
        </thead>
        <tbody>
            {inventur.data.map((project) => {

            return <tr key={project.id} className="border-b border-gray-200">
              <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                <div className="font-medium text-gray-900">{project.produkt}</div>
                
              </td>
              <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{project.menge}</td>
              <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{formatter.format(project.preis)}</td>
              <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">{formatter.format(project.summe)}</td>
            </tr>
              })}
              
          
        </tbody>
        <tfoot>
          <tr>
            <th
              scope="row"
              colSpan={3}
              className="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
            >
              Stückzahl Gesamt
            </th>
            <th scope="row" className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden">
            Stückzahl Gesamt
            </th>
            <td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">{(inventur.data.reduce(function (acc, obj) { return acc + parseInt(obj.menge); }, 0))}</td>
          </tr>
          
          <tr>
            <th
              scope="row"
              colSpan={3}
              className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
            >
              Wert Gesamt
            </th>
            <th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">
              Wert Gesamt
            </th>
            <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
              {formatter.format((inventur.data.reduce(function (acc, obj) { return acc + parseFloat(obj.summe); }, 0)))}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>:<></>

  )
}

export default ID