import { Combobox } from '@headlessui/react'
import { ChevronUpDownIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon, CheckIcon, PlusIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import HeaderBar from '../../components/HeaderBar'
import { BugContext } from '../../components/context'

const CreateNew = () => {

    const [msg, setMsg] = useState("")
    const [vorname, setVorname] = useState("")
    const [nachname, setNachname] = useState("")
    const [strasse, setStrasse] = useState("")
    const [plz, setPlz] = useState("")
    const [email, setEmail] = useState("")
    const [telefon, setTelefon] = useState("")
    const [category, setCategory] = useState("")
    const [product, setProduct] = useState("")
    const [price, setPrice] = useState("")
    const [color, setColor] = useState("")
    const [ekPreis, setEKPrice] = useState("")
    const navigate = useNavigate()
    const [query, setQuery] = useState('')
    const [people, setPeople] = useState([])
    const [selectedPerson, setSelectedPerson] = useState('')

    const [queryProducts, setQueryProducts] = useState('')
    const [selectedProducts, setSelectedProducts] = useState(null)
    const [products, setProducts] = useState([])

    const [queryColors, setQueryColors] = useState('')
    const [selectedColors, setSelectedColors] = useState(null)
    const [colors, setColors] = useState([])

    const [loading, setLoading] = useState(false)
    const [loadingColor, setLoadingColor] = useState(false)
    const [loadingProduct, setLoadingProduct] = useState(false)

    const [success, setSuccess] = useState(false)
    const [successProduct, setSuccessProduct] = useState(false)
    const [successColor, setSuccessColor] = useState(false)

    const { dispatchBugEvent } = useContext(BugContext);
    const location = useLocation();

    const LoadingSpinner = () => (
      // Centralized spinner to be reused
      <div className="flex items-center">
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <span>Lädt...</span>
      </div>
    );


    useEffect(() => {
        dispatchBugEvent('REPORT', {url: window.location.pathname})
      
        return () => {
          dispatchBugEvent('RESET', {url: window.location.pathname})
        }
      }, [])
    useEffect(() => {
      
        try{
         axios.get("https://meisen.waithere.de/api/categories")
          .then((res)=>{
              setPeople(res.data)
          })
        }catch(error){
          console.log(error)
        }
        return () => {
            // Cleanup function to clear data
            setPeople([]);
          };
      }, [])

      useEffect(() => {
      
        try{
        axios.get(`https://meisen.waithere.de/api/products?id=${selectedPerson}`)
          .then((res)=>{
              setProducts(res.data.products)
              setColors(res.data.colors)
          })
        }catch(error){
          console.log(error)
        }

        return () => {
            // Cleanup function to clear data
            setProducts([]);
            setColors([])
          };
      
      }, [selectedPerson, people])

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
  

    const newCategory = async() => {
      setLoading(true)
        try{

          await axios.post('https://meisen.waithere.de/api/new-category', {category: category})
          .then((res)=>{
            setPeople(res.data)
            setLoading(false)
            setSuccess(true)
            setCategory("")
          })
          .catch(()=>{
            setLoading(false)
            setSuccess(false)
          })
        }catch(error){
          console.log(error)

        }
    }

    const newItem = async() => {
      setLoadingProduct(true)
        try{
            await axios.post('https://meisen.waithere.de/api/new-product', {
                category: selectedPerson,
                name: product,
                price: price,
                ekPrice: ekPreis,
            })
            .then((res)=>{
              setSuccessProduct(true)
              setLoadingProduct(false)
              setProduct("")
              setPrice("")
            })
            .catch(()=>{
              setLoadingProduct(false)
              setSuccessProduct(false)
            })
          }catch(error){
            console.log(error)
          }
    }

    const newColor = async() => {
      setLoadingColor(true)
        try{
            await axios.post('https://meisen.waithere.de/api/new-color', {
                category: selectedPerson,
                name: color,
            })
            .then((res)=>{
              setColors(res.data)
              setLoadingColor(false)
              setSuccessColor(true)
              setColor("")
            })
            .catch(()=>{
              setLoadingColor(false)
              setSuccessColor(false)
            })
          }catch(error){
            console.log(error)
          }
    }

  return (
    <>
    <HeaderBar title="Neues Produkt erstellen"/>
    <div className="lg:px-0">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <p className="mt-2 text-sm text-gray-700">
            Legen Sie eine neue Kategorie, Produkt oder Farbe an.
          </p>
        </div>
        
      </div>
      </div>
      <div className="mt-10">

        <form onSubmit={newItem}>
            <div className="shadow sm:rounded-md">
            <div className="bg-white px-4 py-5 sm:p-6">
                {msg &&
                <div className="rounded-md bg-red-50 p-4 mb-2">
                <div className="flex">
                    <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">Fehler beim Anlegen des Produkts</h3>
                    <div className="mt-2 text-sm text-red-700">
                        <ul role="list" className="list-disc space-y-1 pl-5">
                        <li>{msg}</li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
                }
                <div className="grid grid-cols-4 gap-6">

                <div className="col-span-4 sm:col-span-2 sm:col-start-1">
                <label htmlFor="choose" className="block text-sm font-medium leading-6 text-gray-700">
                    Produktkategorie auswählen
                </label>
                <select
                    id="choose"
                    name="location"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={selectedPerson?selectedPerson:"Bitte wählen..."}
                    onChange={e=>setSelectedPerson(e.target.value)}
                >
                    <option value="Bitte wählen..." disabled selected>Bitte wählen...</option>
                    {people.map((location) =>{
                            return <option value={location.id}>{location.name}</option>
                            })
                    }
                </select>
                </div>

                

                    <div className='col-span-4 sm:col-span-2'>
                    <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-700">
                        Fehlende Produktkategorie hinzufügen.
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">

                        <input
                        type="text"
                        name="location"
                        id="location"
                        className="block w-full rounded-md border-0 py-1.5 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Neue Produktkategorie"
                        defaultValue={''}
                        onChange={(e)=>setCategory(e.target.value)}
                        />
                        <div className="absolute justify-center inset-y-0 right-0 flex items-center">
                        <label htmlFor="add" className="sr-only">
                            Hinzufügen
                        </label>
                        {/* <button
                        type="button"
                        onClick={()=>newCategory()}
                        className="h-full rounded-md border-0 bg-transparent py-0 pr-4 pl-4 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm select-none sele"
                        >
                            {success ? <CheckCircleIcon className='h-6 w-6'/> : <PlusIcon className='h-6 w-6'/> }
                        </button> */}
                        </div>
                    </div>
                    <div className="py-3 text-right">
                        <button
                            type="button"
                            disabled={loading}
                            onClick={()=>newCategory()}
                            className={classNames("inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2", success ? "bg-green-600 focus:ring-green-500 hover:bg-green-700":"bg-indigo-600 focus:ring-indigo-500 hover:bg-indigo-700")}
                        >
                            {loading ? <LoadingSpinner/> : success ? 'Erfolgreich erstellt': 'Kategorie hinzufügen'}
                        </button>
                        </div>
                    </div>
                    
                    <div className='border-b border-gray-900/10 pb-2 col-span-4'>

</div>

                    <div className="col-span-4 sm:col-span-2 sm:col-start-1">
                <label htmlFor="choose" className="block text-sm font-medium leading-6 text-gray-700">
                    Verfügbare Farben für {!people?"Kategorie wählen":people.filter(e=>e.id==selectedPerson)[0]?.name}
                </label>
                <select
                    id="choose"
                    name="location"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={selectedColors?selectedColors:"Bitte wählen..."}
                    onChange={e=>setSelectedColors(e.target.value)}
                >
                    <option value="Bitte wählen..." disabled selected>Bitte wählen...</option>
                    <option value="" disabled selected>- Standard Farben -</option>
                    {colors.filter(e=>!e.name.includes("Rooted") && !e.name.includes("Two Tone")).map((location) =>{
                            return <option value={location.id}>{location.name}</option>
                            })
                    }
                    <option value="" disabled selected>- Rooted Farben -</option>
                    {colors.filter(e=>e.name.includes("Rooted")).map((location) =>{
                            return <option value={location.id}>{location.name}</option>
                            })
                    }
                    <option value="" disabled selected>- Two Tone Farben - </option>
                    {colors.filter(e=>e.name.includes("Two Tone")).map((location) =>{
                            return <option value={location.id}>{location.name}</option>
                            })
                    }
                </select>
                </div>


                    <div className='col-span-4 sm:col-span-2'>
                    <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-700">
                        Fehlende Farbe zu {!people?"Kategorie wählen":people?.filter(e=>e.id==selectedPerson)[0]?.name} hinzufügen
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">

                        <input
                        type="text"
                        name="location"
                        id="location"
                        className="block w-full rounded-md border-0 py-1.5 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Neue Farbe"
                        value={color}
                        onChange={(e)=>{setColor(e.target.value);setSuccessColor(false)}}
                        />
                        <div className="absolute justify-center inset-y-0 right-0 flex items-center">
                        <label htmlFor="add" className="sr-only">
                            Hinzufügen
                        </label>
                        {/* <button
                        type="button"
                        onClick={()=>newColor()}
                        className="h-full rounded-md border-0 bg-transparent py-0 pr-4 pl-4 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm select-none sele"
                        >
                            {successColor ? <CheckCircleIcon className='h-6 w-6'/> : <PlusIcon className='h-6 w-6'/> }
                        </button> */}
                        </div>
                    </div>
                    <div className="py-3 text-right">
                        <button
                            type="button"
                            disabled={loadingColor}
                            onClick={()=>newColor()}
                            className={classNames("inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2", successColor ? "bg-green-600 focus:ring-green-500 hover:bg-green-700":"bg-indigo-600 focus:ring-indigo-500 hover:bg-indigo-700")}
                        >
                            {loadingColor ? <LoadingSpinner/> : successColor ? 'Erfolgreich hinzugefügt': 'Farbe hinzufügen'}
                        </button>
                        </div>
                    </div>


                    <div className='border-b border-gray-900/10 pb-2 col-span-4'>

</div>

                    <div className="col-span-4 sm:col-span-2">
                <label htmlFor="choose" className="block text-sm font-medium leading-6 text-gray-700">
                    Verfügbare Produkte
                </label>
                <select
                    id="choose"
                    name="location"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={selectedProducts?selectedProducts:"Bitte wählen..."}
                    onChange={e=>setSelectedProducts(e.target.value)}
                >
                    <option value="Bitte wählen..." disabled selected>Bitte wählen...</option>
                    {products.map((location) =>{
                            return <option value={location.id}>{location.name}</option>
                            })
                    }
                </select>
                </div>


                    

                    <div className='col-span-4 sm:col-span-2'>
                    <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-700">
                        Neues Produkt in {!people?"Kategorie wählen":people?.filter(e=>e.id==selectedPerson)[0]?.name}
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">

                        <input
                        type="text"
                        name="location"
                        id="location"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Neuer Produktname"
                        value={product}
                        onChange={(e)=>{setProduct(e.target.value);setSuccessProduct(false)}}
                        />
                        
                    </div>
                    </div>
                    <div className='col-span-4 sm:col-span-2'>
                    <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-700">
                        Preis für {selectedPerson?.name} im VK?
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">

                        <input
                        type="text"
                        name="location"
                        id="location"
                        className="block w-full rounded-md border-0 py-1.5 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Produktpreis z.B. 90,00"
                        value={price}
                        onChange={(e)=>{setPrice(e.target.value);setSuccessProduct(false)}}
                        />
                        <div className="absolute justify-center inset-y-0 right-0 flex items-center">
                        <label htmlFor="add" className="sr-only">
                            Hinzufügen
                        </label>
                        
                        </div>
                    </div>
                    </div>

                    <div className='col-span-4 sm:col-span-2'>
                    <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-700">
                        Preis für {selectedPerson?.name} im EK?
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">

                        <input
                        type="text"
                        name="location"
                        id="location"
                        className="block w-full rounded-md border-0 py-1.5 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Einkaufspreis z.B. 90,00"
                        value={ekPreis}
                        onChange={(e)=>{setEKPrice(e.target.value);setSuccessProduct(false)}}
                        />
                        <div className="absolute justify-center inset-y-0 right-0 flex items-center">
                        <label htmlFor="add" className="sr-only">
                            Hinzufügen
                        </label>
                        {/* <button
                        type="button"
                        onClick={()=>newItem()}
                        className="h-full rounded-md border-0 bg-transparent py-0 pr-4 pl-4 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm select-none sele"
                        >
                            {successProduct ? <CheckCircleIcon className='h-6 w-6'/> : <PlusIcon className='h-6 w-6'/> }
                        </button> */}
                        </div>
                    </div>
                    </div>


                    

                        </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                        <button
                            type="button"
                            disabled={loadingProduct}
                            onClick={()=>newItem()}
                            className={classNames("inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2", successProduct ? "bg-green-600 focus:ring-green-500 hover:bg-green-700":"bg-indigo-600 focus:ring-indigo-500 hover:bg-indigo-700")}
                        >
                            {loadingProduct ? <LoadingSpinner/> : successProduct ? 'Erfolgreich erstellt': 'Neues Produkt hinzufügen'}
                        </button>
                        </div>
                    </div>
                    </form>

                </div>
    </>
  )
}

export default CreateNew