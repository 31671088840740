import { MinusIcon } from '@heroicons/react/20/solid'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

const Mitarbeiter = () => {

    const [people, setPeople] = useState([])
    const [locations, setLocations] = useState([])
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [selectedLocation, setSelectedLocation] = useState(locations[0]?.id)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
      try{
        axios.get("https://meisen.waithere.de/api/locations")
        .then((res)=>{
            setLocations(res.data)
        })
        axios.get("https://meisen.waithere.de/api/users?all=true")
        .then((res)=>{
            setPeople(res.data)
        })

      }catch(error){
        console.log(error)
      }
    
    }, [loaded])
    
    const addUser = async(e) => {
        e.preventDefault()
        if(!(selectedLocation && email))return
        try {
            await axios.post(`https://meisen.waithere.de/api/admin-register`, {
                name: name,
                email: email,
                location: selectedLocation,
            })
            .then((res)=>{
                setLoaded(!loaded)
            })
        } catch (error) {
            if (error.response) {
            }
        }
        
    }


  return (
    <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">

                    <div className="shadow sm:overflow-hidden sm:rounded-md">
                        <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
                        

              <div className="mx-auto max-w-md sm:max-w-3xl mt-10">
                <div>
                    <div className="text-center">
                    <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                    >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                    </svg>
                    <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">Mitarbeiter hinzufügen</h2>
                    <p className="mt-1 text-sm text-gray-500">Verwalten Sie bestehende Mitarbeiter oder fügen Sie neue hinzu.</p>
                    </div>
                    <form onSubmit={addUser} className="mt-6 sm:flex sm:items-center">
                    <input
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full sm:w-auto rounded-md sm:rounded-none sm:rounded-l-md -ml-px border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Name des Mitarbeiters"
                        value={name}
                        onChange={(e)=>{setName(e.target.value)}}
                        />
                        
                    <label htmlFor="emails" className="sr-only">
                        Emailadresse
                    </label>
                    <div className="grid grid-cols-1 sm:flex-auto">
                        <input
                        type="email"
                        name="emails"
                        id="emails"
                        className="peer relative col-start-1 row-start-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Emailadresse des Mitarbeiters"
                        value={email}
                        onChange={(e)=>{setEmail(e.target.value)}}
                        />
                        <div
                        className="col-start-1 -ml-px col-end-4 row-start-1 rounded-r-md shadow-sm ring-1 ring-inset ring-gray-300 peer-focus:ring-2 peer-focus:ring-indigo-600"
                        aria-hidden="true"
                        />
                        <div className="col-start-3 row-start-1 flex items-center">
                        <span className="h-4 w-px flex-none bg-gray-200" aria-hidden="true" />
                        <label htmlFor="role" className="sr-only">
                            Standort
                        </label>
                        <select
                            id="role"
                            name="role"
                            className="rounded-md border-0 bg-transparent py-1.5 pl-4 pr-7 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            defaultValue={selectedLocation}
                            onChange={(e)=>{setSelectedLocation(e.target.value)}}
                        >
                            <option value="">Standort wählen</option>
                            {locations.map((location) =>{
                            return <option value={location.id}>{location.name}</option>
                            })
                            }
                        </select>
                        </div>
                    </div>
                    
                    <div className="mt-3 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
                        <button
                        type="submit"
                        className="block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                        Hinzufügen
                        </button>
                    </div>
                    </form>
                </div>
                <div className="mt-10">
                    <h3 className="text-sm font-medium text-gray-500">Derzeitige Miarbeiter</h3>
                    <ul role="list" className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
                    {people.map((person, personIdx) => (
                        <li key={personIdx}>
                        <button
                            type="button"
                            className="group flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            <span className="flex min-w-0 flex-1 items-center space-x-3">
                            <span className="block flex-shrink-0">
                            <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
                                <span className="font-medium leading-none text-white">RM</span>
                            </span>

                            </span>
                            <span className="block min-w-0 flex-1">
                                <span className="block truncate text-sm font-medium text-gray-900">{person.username} <span className='truncate text-xs font-medium text-gray-500'>{person.email}</span> </span>
                                <span className="block truncate text-sm font-medium text-gray-500">{person.stock.name}</span>
                            </span>
                            </span>
                            <span className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
                            <MinusIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            </span>
                        </button>
                        </li>
                    ))}
                    </ul>
                </div>
                </div>
                </div>
                </div>
    
    </div>
  )
}

export default Mitarbeiter