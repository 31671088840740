import { Link } from 'react-router-dom'
import HeaderBar from '../../components/HeaderBar'
import { Combobox, Disclosure, Listbox, RadioGroup, Transition } from '@headlessui/react'
import { CheckCircleIcon, ChevronUpDownIcon, ExclamationCircleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import axios from 'axios'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CalendarIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, MinusCircleIcon, MinusSmallIcon, PlusCircleIcon, PlusSmallIcon, TagIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import Datepicker from "react-tailwindcss-datepicker"; 
import { BugContext } from '../../components/context'


export function useHorizontalScroll() {
  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = e => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
         });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return elRef;
}

const Create = (props) => {

    const assignees = [
        { name: 'Single', value: null },
        { name: 'Split', value: 'Split',},
        // More items...
      ]
      const labels = [
        { name: 'Keine', value: null },
        // { name: 'Wavy', value: "Wavy" },
        { name: 'Dark Bonds', value: "Dark-Bonds" },
        // More items...
      ]
      const dueDates = [
        { name: 'Keine', value: null },
        { name: 'Reklamation', value: 'Reklamation' },
        { name: 'PR./Model', value: 'Model' },
        // More items...
      ]


    const [artikelName, setArtikelName] = useState("")
    const [description, setDescription] = useState("")
    const [price, setPrice] = useState("")
    const [teileCode, setTeileCode] = useState("OEM")
    const [partsNumber, setPartsNumber] = useState("")
    const [projects, setProjects] = useState([])
    const [tags, setTags] = useState("")
    const [selectedManufacturer, setSelectedManufacturer] = useState("harley-davidson");
    const [success, setSuccess] = useState(false)
    const [status, setStatus] = useState("error") 
    const [fileName, setFileName] = useState("");
    const [zustand, setRadioValue] = useState('Neu');
    const [type, setTypeValue] = useState('');
    const [pricing, setPricingText] = useState('');
    const [productFee, setProductFee] = useState("0.00")
    const [file, setFile] = useState();
    const [msg, setMsg] = useState("")
    const [selectedMailingLists, setSelectedMailingLists] = useState()
    const [image, setImage] = useState(null)
    const navigate = useNavigate()
    const [people, setPeople] = useState([])
    const [category, setCategory] = useState([])
    const [location, setLocation] = useState([])
    const [user, setUser] = useState([])
    const [product, setProduct] = useState([])
    const [color, setColor] = useState([])
    const [menge, setMenge] = useState(null)
    const [query, setQuery] = useState('')
    const [limit, setLimit] = useState(false)
    const [selectedPerson, setSelectedPerson] = useState(null)
    const [locationQuery, setLocationQuery] = useState('')
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [userQuery, setUserQuery] = useState('')
    const [selectedUser, setSelectedUser] = useState(null)
    const [productQuery, setProductQuery] = useState('')
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [colorQuery, setColorQuery] = useState('')
    const [selectedColor, setSelectedColor] = useState(null)
    const [categoriesQuery, setCategoriesQuery] = useState('')
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [assigned, setAssigned] = useState(assignees[0])
    const [labelled, setLabelled] = useState(labels[0])
    const [dated, setDated] = useState(dueDates[0])
    const [adress, setAdress] = useState([])
    const [selectedAdress, setSelectedAdress] = useState("")
    const [brushes, setBrushes] = useState([])
    const [brushesList, setBrushesList] = useState([])
    const [selectedBrush, setSelectedBrush] = useState("")
    const [reklamation, setReklamation] = useState("")
    const [rechnung, setRechnung] = useState("")
    const [date, setDate] = useState("")
    const [additionals, setAdditionals] = useState([])
    const [aufwand, setAufwand] = useState("")
    const [loading, setLoading] = useState(false)
    const scrollRef = useHorizontalScroll();
    const [maxLimit, setMaxLimit] = useState(null)
    const [show, setShow] = useState(false)
    const [brushNotice, setBrushNotice] = useState(false)
    const [value, setValue] = useState({ 
      startDate: null, 
      endDate: null
      }); 


      
      const handleValueChange = (newValue) => {
      newValue.startDate ? setDate(newValue.startDate.split("-").reverse().join(".")) : setDate("")
      
      setValue(newValue); 
      } 


    // person.vorname.toLowerCase().includes(query.toLowerCase()) || person.nachname.toLowerCase().includes(query.toLowerCase())
    const { dispatchBugEvent } = useContext(BugContext);

    useEffect(() => {
      dispatchBugEvent('REPORT', {url: window.location.pathname})
    
      return () => {
        console.log("Reset")
         dispatchBugEvent('RESET', {url: window.location.pathname})
      }
    }, [])
    

    useEffect(() => {
        try{
          axios.get("https://meisen.waithere.de/api/customers")
          .then((res)=>{
              setPeople(res.data)
          })
  
        }catch(error){
              console.log(error)
        }

        return () => {
          // Cleanup function to clear data
          setPeople([]);
        };
      
      }, [])

      useEffect(() => {
      
        try{
          axios.get("https://meisen.waithere.de/api/locations")
          .then((res)=>{
              setLocation(res.data)
          })
        }catch(error){
          console.log(error)
        }

        return () => {
          // Cleanup function to clear data
          setLocation([]);
        };
      
      }, [])

      useEffect(() => {
      
        try{
          axios.get("https://meisen.waithere.de/api/users")
          .then((res)=>{
              setUser(res.data)
          })
        }catch(error){
          console.log(error)
        }

        return () => {
          // Cleanup function to clear data
          setUser([]);
        };
      
      }, [])

      useEffect(() => {
      
        try{
          axios.get(`https://meisen.waithere.de/api/additionals?location=${selectedLocation?.id}`)
          .then((res)=>{
              setAdress(res.data.adress)
              setBrushes(res.data.brushes)
              setBrushesList(res.data.available)
          })
        }catch(error){
          console.log(error)
        }
      
        return () => {
          // Cleanup function to clear data
          setAdress([]);
          setBrushes([]);
          setBrushesList([])
        };
      }, [selectedLocation])

      useEffect(() => {
      
        try{
         axios.get("https://meisen.waithere.de/api/categories")
          .then((res)=>{
              setCategory(res.data)
          })
        }catch(error){
          console.log(error)
        }
        return () => {
          // Cleanup function to clear data
          setCategory([]);
        };
      }, [])
        

      useEffect(() => {
      
        try{
          axios.get(`https://meisen.waithere.de/api/products?location=${selectedLocation?.id}&id=${selectedCategory?.id}`)
          .then((res)=>{
              setProduct(res.data.available)
          })
        }catch(error){
          console.log(error)
        }

        return () => {
          // Cleanup function to clear data
          setProduct([]);
        };
      
      }, [selectedCategory, selectedLocation])

      useEffect(() => {

        setSelectedColor(null)
      setPrice(selectedProduct?.product.price)
        try{
          axios.get(`https://meisen.waithere.de/api/colors?location=${selectedLocation?.id}&product=${selectedProduct?.product_id}&category=${selectedCategory?.id}`)
          .then((res)=>{
              setColor(res.data.colors)
          })
        }catch(error){
          console.log(error)
        }

        return () => {
          // Cleanup function to clear data
          setColor([]);
        };
      
      }, [selectedCategory, selectedProduct])

      useEffect(()=>{
          setMaxLimit(selectedColor?.availables[0].available)
      },[selectedColor])
      
        const filteredPeople =
          query === ''
            ? people
            : people.filter((person) => {
                return new RegExp('(?:^|\\s)'+query, 'i').test(person.vorname + " " + person.nachname) 
              })
        const filteredLocation =
        locationQuery === ''
        ? location
        : location.filter((person) => {
            return person.name.toLowerCase().includes(locationQuery.toLowerCase())
            })  
        const filteredUser =
        userQuery === ''
        ? user
        : user.filter((person) => {
            return person.username.toLowerCase().includes(userQuery.toLowerCase())
            })   
        const filteredColors =
        colorQuery === ''
        ? color
        : color.filter((person) => {
            return person.name.toLowerCase().includes(colorQuery.toLowerCase())
            })     
        const filteredProducts =
        productQuery === ''
        ? product
        : product.filter((person) => {
            return person.product.name.toLowerCase().includes(productQuery.toLowerCase())
            }) 
        const filteredCategories =
        categoriesQuery === ''
        ? category
        : category.filter((person) => {
            return person.name.toLowerCase().includes(categoriesQuery.toLowerCase())
            })      
            
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }    


const setTeilenummer = ()=> {


    if(!(selectedColor && selectedProduct)){
        setMsg("Bitte Produkt und Farbe wählen")
        return 
    }
    var additions = 0
    if(assigned.value)additions += 2
    if(labelled.value === "Wavy")additions += 2
    if(labelled.value === "Dark-Bonds")additions += 2.5

    console.log(additions)

    let tempObject = {}
    tempObject.name = selectedProduct.product.name
    tempObject.product_id = selectedProduct.product.id
    tempObject.color = selectedColor.name
    tempObject.color_id = selectedColor.id
    tempObject.cat= selectedCategory.id
    tempObject.menge = assigned.value !== null ? menge*2:menge
    if(assigned.value){
        tempObject.price = price ? ((parseFloat(price)+additions)/2).toFixed(2):((product.price+additions)/2).toFixed(2)
        tempObject.gesamt = dated.value === null ? price ? ((parseFloat(price)+additions)/2).toFixed(2)*menge*2:((product.price+additions)/2).toFixed(2)*menge*2:0
    } else {
        tempObject.price = price ? (parseFloat(price)+additions).toFixed(2):(product.price+additions).toFixed(2)
        tempObject.gesamt = dated.value === null ? price ? (parseFloat(price)+additions).toFixed(2)*menge:(product.price+additions)*menge:0

    }
    tempObject.split = assigned.value
    tempObject.addons = labelled.value
    tempObject.reklamation = dated.value
    setMsg("")
    setMenge("")
    setSelectedProduct(null)
    setSelectedColor(null)
    setPrice("")
    setLabelled(labels[0])
    setAssigned(assignees[0])
    setDated(dueDates[0])
    setProjects([...projects, tempObject]);
    dispatchBugEvent('REPORT', { data: tempObject });
}  

const handleFocus = (event) => event.target.select()

const handleBrushSelect = (e) =>{
  setBrushNotice(false)

  const brush = e.target.value;
  const brushArray = brush.split('-');
  const brushId = brushArray[0]

  const foundBrush = brushesList.find(b=>b.product_id === parseInt(brushId))
  
  if(foundBrush && foundBrush.available === 0){
    setBrushNotice(true)
  }


  setSelectedBrush(e.target.value)
}

const removeCode = (id) =>{
    let array = [...projects]; // make a separate copy of the array
    if (id !== -1) {
      array.splice(id, 1);
      setProjects(array);
    }
  }

  const handlePrice = (e) => {
    let converted = e.replace(",", ".")
    let price = parseFloat(converted)
    setPrice(converted)
};  

const handleMenge = (e) => {
    if(maxLimit < e){
        setLimit(true)
    }else{setLimit(false)}
    setMenge(e)
}; 


// const Fix = () => {
//     try{
//          axios.post(`https://meisen.waithere.de/api/fixIt`)
//         .then(res => {
//             console.log(res)
//           })
//     }catch(error){
//         console.log(error)
//     }
// }
function debugBase64(base64URL){
  var win = window.open();
  win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
}

function downloadBlobFile(blob, fileName) {
  const isIE = !!document.documentMode;
  if (isIE) {
      window.navigator.msSaveBlob(blob, fileName);
  } else {
      let blob2 = new Blob( [blob], { type: "application/pdf" });
      const link = URL.createObjectURL(blob2);
      const a = document.createElement("a");
      a.setAttribute("download", fileName);
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  }
}

console.log(selectedBrush)

const PostBeleg = async (e) => {
    e.preventDefault();
      setMsg("")
      setLoading(true)

    if(!(selectedPerson && selectedLocation && selectedUser)){
        setMsg("Bitte alle Felder auswählen")
        setLoading(false)
        return
    } 
        
    try {
        await axios.post(`https://meisen.waithere.de/api/create`, {projects, brush:selectedBrush, altadress:selectedAdress, reklamation:reklamation, rechnung:rechnung, altdate:date, kunde:selectedPerson.id, employee:selectedUser.id, standort:selectedLocation.id, aufwand: aufwand })
        .then(res => {
            setLoading(false)
                        // var blob = new Blob([res.data], {type: "application/pdf"});
            // var objectUrl = URL.createObjectURL(blob);
            // window.open(res.data);
            // debugBase64(res.data)
            setMsg("")
            setMenge("")
            setSelectedProduct(null)
            setSelectedColor(null)
            setPrice("")
            setLabelled(labels[0])
            setAssigned(assignees[0])
            setDated(dueDates[0])
            setProjects([])
            fetch(res.data)
                          .then(res=>res.arrayBuffer())
                          .then(data=>{
                            console.log(data)
                            downloadBlobFile(data, "beleg.pdf")})
            

          })
          .catch((error) => {
            setLoading(false)
            setMsg("Bitte erneut versuchen und/oder eine stabile Internetverbindung verwenden.")
            console.log(error);
          });
        
    } catch (error) {
            setLoading(false)
            setMsg("Bitte erneut versuchen.")
            console.log(error)
        
    }
}
const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });
  return (
    <>
    <HeaderBar title="Beleg erstellen" />
      <div className="lg:px-0">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <p className="mt-2 text-sm text-white">
            Erstellen Sie gemischte Belege in einem Formular oder fügen Sie neue Artikel hinzu.
          </p>
        </div>
        { props.role === "Admin" &&
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to="new"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Produkt anlegen
          </Link>
        </div>
}
      </div>
      </div>
      <div className="mt-10">

        <form onSubmit={PostBeleg} autoComplete="off">
            <div className="overflow-hidden shadow sm:rounded-md">
            <div className="bg-white px-4 py-5 sm:p-6">
                {msg &&
                <div className="rounded-md bg-red-50 p-4 mb-2">
                <div className="flex">
                    <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">Fehler beim erstellen des Beleges</h3>
                    <div className="mt-2 text-sm text-red-700">
                        <ul role="list" className="list-disc space-y-1 pl-5">
                        <li>{msg}</li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
                }
                <div className="grid grid-cols-6 gap-6">

                <Combobox as="div" className="col-span-6 sm:col-span-2" value={selectedLocation} onChange={setSelectedLocation}>
                    <Combobox.Label className="block text-sm font-medium leading-6 text-gray-700">Standort auswählen</Combobox.Label>
                    <div className="relative mt-2">
                        <Combobox.Input
                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(event) => setLocationQuery(event.target.value.trimStart())}
                        displayValue={(person) => person?.name}
                        autoComplete="off"
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </Combobox.Button>

                        {filteredLocation.length > 0 && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredLocation.map((person) => (
                            <Combobox.Option
                                key={person.id}
                                value={person}
                                className={({ active }) =>
                                classNames(
                                    'relative cursor-default select-none py-2 pl-3 pr-9',
                                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                )
                                }
                            >
                                {({ active, selected }) => (
                                <>
                                    <span className={classNames('block truncate', selected && 'font-semibold')}>{person.name}</span>

                                    {selected && (
                                    <span
                                        className={classNames(
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                        active ? 'text-white' : 'text-indigo-600'
                                        )}
                                    >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                    )}
                                </>
                                )}
                            </Combobox.Option>
                            ))}
                        </Combobox.Options>
                        )}
                    </div>
                    </Combobox>

                    <Combobox as="div" className="col-span-6 sm:col-span-2" value={selectedUser} onChange={setSelectedUser}>
                    <Combobox.Label className="block text-sm font-medium leading-6 text-gray-700">Mitarbeiter auswählen</Combobox.Label>
                    <div className="relative mt-2">
                        <Combobox.Input
                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(event) => setUserQuery(event.target.value.trimStart())}
                        displayValue={(person) => person?.username}
                        autoComplete="off"
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </Combobox.Button>

                        {filteredUser.length > 0 && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredUser.map((person) => (
                            <Combobox.Option
                                key={person.id}
                                value={person}
                                className={({ active }) =>
                                classNames(
                                    'relative cursor-default select-none py-2 pl-3 pr-9',
                                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                )
                                }
                            >
                                {({ active, selected }) => (
                                <>
                                    <span className={classNames('block truncate', selected && 'font-semibold')}>{person.username}</span>

                                    {selected && (
                                    <span
                                        className={classNames(
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                        active ? 'text-white' : 'text-indigo-600'
                                        )}
                                    >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                    )}
                                </>
                                )}
                            </Combobox.Option>
                            ))}
                        </Combobox.Options>
                        )}
                    </div>
                    </Combobox>

                    <Combobox as="div" className="col-span-6 sm:col-span-2" value={selectedPerson} onChange={setSelectedPerson}>
                    <Combobox.Label className="block text-sm font-medium leading-6 text-gray-700">Kunde auswählen</Combobox.Label>
                    <div className="relative mt-2">
                        <Combobox.Input
                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onFocus={handleFocus}
                        onChange={(event) => setQuery(event.target.value.trimStart())}
                        displayValue={(person) => person?person?.vorname + " " + person?.nachname : "Bitte wählen..."}
                        autoComplete="off"
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </Combobox.Button>

                        {filteredPeople.length > 0 && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredPeople.map((person, personIdx) => (
                            <Combobox.Option
                                key={personIdx}
                                value={person}
                                className={({ active }) =>
                                classNames(
                                    'relative cursor-default select-none py-2 pl-3 pr-9',
                                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                )
                                }
                            >
                                {({ active, selected }) => (
                                <>
                                    <span className={classNames('block truncate', selected && 'font-semibold')}>{person.vorname + " " + person.nachname}</span>

                                    {selected && (
                                    <span
                                        className={classNames(
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                        active ? 'text-white' : 'text-indigo-600'
                                        )}
                                    >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                    )}
                                </>
                                )}
                            </Combobox.Option>
                            ))}
                        </Combobox.Options>
                        )}
                    </div>
                    </Combobox>

                    <div className='border-b border-gray-900/10 pb-2 col-span-6'>

                    </div>

                    <Combobox as="div" className="col-span-6 sm:col-span-2" value={selectedCategory} onChange={setSelectedCategory}>
                    <Combobox.Label className="block text-sm font-medium leading-6 text-gray-700">Produktkategorie auswählen</Combobox.Label>
                    <div className="relative mt-2">
                        <Combobox.Input
                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(event) => setCategoriesQuery(event.target.value.trimStart())}
                        displayValue={(person) => person?.name}
                        autoComplete="off"
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </Combobox.Button>

                        {filteredCategories.length > 0 && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredCategories.map((person, personIdx) => (
                            <Combobox.Option
                                key={personIdx}
                                value={person}
                                className={({ active }) =>
                                classNames(
                                    'relative cursor-default select-none py-2 pl-3 pr-9',
                                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                )
                                }
                            >
                                {({ active, selected }) => (
                                <>
                                    <span className={classNames('block truncate', selected && 'font-semibold')}>{person.name}</span>

                                    {selected && (
                                    <span
                                        className={classNames(
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                        active ? 'text-white' : 'text-indigo-600'
                                        )}
                                    >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                    )}
                                </>
                                )}
                            </Combobox.Option>
                            ))}
                        </Combobox.Options>
                        )}
                    </div>
                    </Combobox>

                    <Combobox as="div" className="col-span-6 sm:col-span-2" value={selectedProduct} onChange={setSelectedProduct}>
                    <Combobox.Label className="block text-sm font-medium leading-6 text-gray-700">Produkt auswählen</Combobox.Label>
                    <div className="relative mt-2">
                        <Combobox.Input
                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(event) => setProductQuery(event.target.value.trimStart())}
                        displayValue={(person) => person?.product.name}
                        autoComplete="off"
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </Combobox.Button>

                        {filteredProducts.length > 0 && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredProducts.map((person) => (
                            <Combobox.Option
                                key={person.id}
                                value={person}
                                className={({ active }) =>
                                classNames(
                                    'relative cursor-default select-none py-2 pl-3 pr-9',
                                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                )
                                }
                            >
                                {({ active, selected }) => (
                                <>
                                    <span className={classNames('block truncate', selected && 'font-semibold')}>{person.product.name}</span>

                                    {selected && (
                                    <span
                                        className={classNames(
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                        active ? 'text-white' : 'text-indigo-600'
                                        )}
                                    >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                    )}
                                </>
                                )}
                            </Combobox.Option>
                            ))}
                        </Combobox.Options>
                        )}
                    </div>
                    </Combobox>

                    <Combobox as="div" className="col-span-6 sm:col-span-2" value={selectedColor} onChange={setSelectedColor}>
                    <Combobox.Label className="block text-sm font-medium leading-6 text-gray-700">Farbe auswählen</Combobox.Label>
                    <div className="relative mt-2">
                        <Combobox.Input
                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(event) => setColorQuery(event.target.value.trimStart())}
                        displayValue={(person) => selectedColor ? person?.name+ " Verfügbar: " +person?.availables[0].available: ""}
                        autoComplete="off"
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </Combobox.Button>

                        {filteredColors.length > 0 && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredColors.map((person) => (
                            <Combobox.Option
                                key={person.id}
                                value={person}
                                className={({ active }) =>
                                classNames(
                                    'relative cursor-default select-none py-2 pl-3 pr-9 ',
                                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                )
                                }
                            >
                                {({ active, selected }) => (
                                <>
                                    <span className={classNames('block truncate', selected && 'font-semibold')}>{person.name}<span className='text-xs font-bold'>{" Verfügbar: " +person.availables[0].available}</span></span>

                                    {selected && (
                                    <span
                                        className={classNames(
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                        active ? 'text-white' : 'text-indigo-600'
                                        )}
                                    >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                    )}
                                </>
                                )}
                            </Combobox.Option>
                            ))}
                        </Combobox.Options>
                        )}
                    </div>
                    </Combobox>

                    <div className='col-span-6 lg:col-span-2'>
                    <label className="block text-sm font-medium leading-6 text-gray-900">Optionen</label>
        {/* Actions: These are just examples to demonstrate the concept, replace/wire these up however makes sense for your project. */}
        <div ref={scrollRef} className="flex flex-nowrap justify-between space-x-2 py-2 px-2 sm:px-3 sm:overflow-x-scroll overflow-visible no-scrollbar">
          <Listbox as="div" value={assigned} onChange={setAssigned} className="flex-shrink-0">
            {({ open }) => (
              <>
                <Listbox.Label className="sr-only"> Split </Listbox.Label>
                <div className="relative">
                  <Listbox.Button className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 py-2 px-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                      <UserCircleIcon className="h-5 w-5 flex-shrink-0 text-gray-600 sm:-ml-1" aria-hidden="true" />
                   

                    <span
                      className={classNames(
                        assigned.value === null ? '' : 'text-gray-900',
                        'hidden truncate sm:ml-2 sm:block'
                      )}
                    >
                      {assigned.value === null ? 'Split?' : assigned.name}
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute sm:fixed  z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {assignees.map((assignee) => (
                        <Listbox.Option
                          key={assignee.value}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-gray-100' : 'bg-white',
                              'relative cursor-default select-none py-2 px-3'
                            )
                          }
                          value={assignee}
                        >
                          <div className="flex items-center">
                            <span className="block truncate font-medium">{assignee.name}</span>
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>

          {/* <Listbox as="div" value={labelled} onChange={setLabelled} className="flex-shrink-0">
            {({ open }) => (
              <>
                <Listbox.Label className="sr-only"> Zusätze </Listbox.Label>
                <div className="relative">
                  <Listbox.Button className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 py-2 px-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                    <TagIcon
                      className={classNames(
                        labelled.value === null ? 'text-gray-600' : 'text-gray-500',
                        'h-5 w-5 flex-shrink-0 sm:-ml-1'
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        labelled.value === null ? '' : 'text-gray-900',
                        'hidden truncate sm:ml-2 sm:block'
                      )}
                    >
                      {labelled.value === null ? 'Zusätze' : labelled.name}
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute sm:fixed max-sm:right-[-4rem] z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {labels.map((label) => (
                        <Listbox.Option
                          key={label.value}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-gray-100' : 'bg-white',
                              'relative cursor-default select-none py-2 px-3'
                            )
                          }
                          value={label}
                        >
                          <div className="flex items-center">
                            <span className="block truncate font-medium">{label.name}</span>
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox> */}

          <Listbox as="div" value={dated} onChange={setDated} className="flex-shrink-0">
            {({ open }) => (
              <>
                <Listbox.Label className="sr-only"> Reklamation </Listbox.Label>
                <div className="relative">
                  <Listbox.Button className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 py-2 px-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                    <CalendarIcon
                      className={classNames(
                        dated.value === null ? 'text-gray-600' : 'text-gray-500',
                        'h-5 w-5 flex-shrink-0 sm:-ml-1'
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        dated.value === null ? '' : 'text-gray-900',
                        'hidden truncate sm:ml-2 sm:block'
                      )}
                    >
                      {dated.value === null ? 'Reklamation' : dated.name}
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute sm:fixed max-sm:right-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {dueDates.map((dueDate) => (
                        <Listbox.Option
                          key={dueDate.value}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-gray-100' : 'bg-white',
                              'relative cursor-default select-none py-2 px-3'
                            )
                          }
                          value={dueDate}
                        >
                          <div className="flex items-center">
                            <span className="block truncate font-medium">{dueDate.name}</span>
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>

                    </div>

                    {/* LISTBOX END ________________________ */}

                    <div className='col-span-2 lg:col-span-1'>
                        <label htmlFor="menge" className="block text-sm font-medium leading-6 text-gray-900">
                        Menge
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                        <input
                            autoComplete='off'
                            type="number"
                            name="menge"
                            id="menge"
                            min={0}
                            className={
                                classNames(
                                    'block w-full rounded-md border-0 py-1.5 pr-10 ring-1 ring-inset  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6',
                                    limit ?  'ring-red-300 focus:ring-red-500 text-red-900 placeholder:text-red-300' : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600'
                                )
                                }
                            aria-invalid="true"
                            aria-describedby="email-error"
                            placeholder='0'
                            value={menge}
                            onChange={(e)=>{handleMenge(e.target.value)}}
                        />{limit &&
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>}
                        </div>
                        {limit&&
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                        Die Menge überschreitet den Bestand.
                        </p>}
                        </div>

                        <div className='col-span-2 lg:col-span-1'>
                        <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                            Preis
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">€</span>
                            </div>
                            <input
                            autoComplete='off'
                            type="text"
                            name="price"
                            id="price"
                            className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="0,00"
                            value={price}
                            onChange={(e)=>{handlePrice(e.target.value)}}
                            aria-describedby="price-currency"
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                EUR
                            </span>
                            </div>
                        </div>
                    </div>


                    <div className="col-span-2 ">
                    <label className="block text-sm font-medium leading-6 text-gray-900">{selectedProduct?.product.name?selectedProduct?.product.name:"Bitte Produkt wählen"}</label>
                    <div className="relative mt-2 rounded-md">

                        <button
                            type="button"
                            onClick={()=>setTeilenummer()}
                            className="hidden lg:inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-1.5 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Zum Beleg hinzufügen
                        </button>
                        <button
                            type="button"
                            onClick={()=>setTeilenummer()}
                            className="lg:hidden inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-1.5 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Hinzufügen
                        </button>
                        </div>
                        </div>

                    <div className='border-b border-gray-900/10 pb-2 col-span-6'>

                    </div>

                           
                        </div>
                        {projects.length <= 0 ? <div className="-mx-4 mt-8 flow-root sm:mx-0 text-sm text-gray-700 font-medium text-center">Noch keine Artikel hinzugefügt</div> :
                        <div className="-mx-4 mt-8 flow-root sm:mx-0">
                            <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                <th scope="col" className="">
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    Position
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    Artikel
                                </th>
                                <th
                                    scope="col"
                                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                                >
                                    Menge
                                </th>
                                <th
                                    scope="col"
                                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                                >
                                    Preis
                                </th>
                                <th scope="col" className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                                    Gesamt
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                {projects.map((project, index) => (
                                <tr key={index} className="border-b border-gray-200">
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        <button type='button' onClick={()=>{removeCode(index)}} className="text-indigo-600 hover:text-indigo-900">
                                        <MinusCircleIcon className='w-5 h-5 text-gray-500'/><span className="sr-only">, {project.name}</span>
                                        </button>
                                    </td> 
                                    <td className="py-4 pl-4 pr-3 text-sm sm:pl-0">
                                    <div className="font-medium text-gray-900">{index+1}</div>
                                    
                                    </td>
                                    <td className="py-4 pl-4 pr-3 text-sm sm:pl-0">
                                    <div className="font-medium text-gray-900">{project.name} {project.color} {project.split === null ? "":project.split} {project.reklamation === null ? "":" Reklamation"} {project.addons === null ? "":project.addons}</div>
                                    
                                    </td>
                                    <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{project.menge}</td>
                                    <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{parseFloat(project.price).toFixed(2)}</td>
                                    <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">{parseFloat(project.gesamt).toFixed(2)}</td>
                                </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                <th
                                    scope="row"
                                    colSpan={5}
                                    className="hidden pl-4 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                                >
                                    Zwischensumme
                                </th>
                                <th scope="row" className="pl-6 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden">
                                    Zwischensumme
                                </th>
                                <td className="pl-3 pr-6 pt-6 text-right text-sm text-gray-500 sm:pr-0">{formatter.format((projects.reduce(function (acc, obj) { return acc + obj.gesamt; }, 0)) /1.19 )}</td>
                                </tr>
                                <tr>
                                <th
                                    scope="row"
                                    colSpan={5}
                                    className="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                                >
                                    19% MwSt.
                                </th>
                                <th scope="row" className="pl-6 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden">
                                    19% MwSt.
                                </th>
                                <td className="pl-3 pr-6 pt-4 text-right text-sm text-gray-500 sm:pr-0">{formatter.format((projects.reduce(function (acc, obj) { return acc + obj.gesamt; }, 0)) - (projects.reduce(function (acc, obj) { return acc + obj.gesamt; }, 0)) /1.19 )}</td>
                                </tr>
                                <tr>
                                <th
                                    scope="row"
                                    colSpan={5}
                                    className="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
                                >
                                    Total
                                </th>
                                <th scope="row" className="pl-6 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">
                                    Total
                                </th>
                                <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">{formatter.format(projects.reduce(function (acc, obj) { return acc + obj.gesamt; }, 0))} {aufwand>0?"+ "+formatter.format(aufwand):""}</td>
                                </tr>
                            </tfoot>
                            </table>
                        </div>}      

                        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        <Disclosure as="div" className="pt-6">
                            {({ open }) => (
                            <>
                                <dt>
                                <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                    <span className="block text-sm font-medium leading-6 text-gray-900">Zusätzliche Felder</span>
                                    <span className="ml-6 flex h-7 items-center">
                                    {open ? (
                                        <ChevronUpIcon className="h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
                                    )}
                                    </span>
                                </Disclosure.Button>
                                </dt>
                                <Disclosure.Panel as="dd" className="mt-2">
                                <div className="grid grid-cols-6 gap-6">

                                    <div className='col-span-6 sm:col-span-2'>
                                    <label htmlFor="aufwand" className="block text-sm font-medium leading-6 text-gray-700">
                                        Aufwand
                                    </label>
                                    <select
                                        id="aufwand"
                                        name="aufwand"
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        defaultValue=""
                                        onChange={(e)=>setAufwand(e.target.value)}
                                    >
                                        <option value={0}>nicht ausgewählt</option>
                                        <option value={105.00}>Hairwear Befestigung (+105,00€)</option>
                                        <option value={125.00}>Tresse 1. Reihe (+125,00€)</option>
                                        <option value={200.00}>Tresse 2. Reihe (+200,00€)</option>
                                        <option value={275.00}>Tresse 3. Reihe (+275,00€)</option>
                                        <option value={325.00}>Tresse 4. Reihe (+325,00€)</option>
                                        <option value={105.00}>Entfernen Fremdextensions (+105,00€)</option>

                                        
                                    </select>
                                    </div>

                                    <div className='col-span-6 sm:col-span-2'>
                                    <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-700">
                                        Bürste 
                                    </label>
                                    <select
                                        id="altadress"
                                        name="altadress"
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        defaultValue={selectedBrush}
                                        onChange={(e)=>handleBrushSelect(e)}
                                    >
                                        <option value="">nicht ausgewählt</option>
                                        {/* {brushes.map((brush)=>{
                                            return <option value={brush.id}>{brush.variant} {formatter.format(brush.price)}</option>
                                        })

                                        } */}
                                        {brushesList.map((brush)=>{
                                            return <option value={brush.product_id + "-" + brush.color_id + "-"+ brush.product.name}>{brush.available}: {brush.product.name} {formatter.format(brush.product.price)}</option>
                                        })

                                        }
                                    </select>
                                    {brushNotice? <p className='text-sm text-red-600 font-bold'>Es ist kein Bestand für diese Bürste hinterlegt. Bitte überprüfen und ggfs. nachtragen. Der Beleg wird trotzdem erstellt.</p> : ""}
                                    </div>
                                
                                <div className='col-span-5 sm:col-span-2'>
                                    <label htmlFor="reklamation" className="block text-sm font-medium leading-6 text-gray-900">
                                        Reklamationsgrund
                                    </label>
                                    <div className="mt-2">
                                        <input
                                        type="text"
                                        name="reklamation"
                                        id="rklamation"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="Grund"
                                        value={reklamation}
                                        onChange={(e)=>setReklamation(e.target.value)}
                                        />
                                    </div>
                                    </div>
                                    <div className='col-span-5 sm:col-span-2'>
                                    <label htmlFor="nummer" className="block text-sm font-medium leading-6 text-gray-900">
                                        Rechnungsnummer
                                    </label>
                                    <div className="mt-2">
                                        <input
                                        type="text"
                                        name="nummer"
                                        id="nummer"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="Ihre Nummer"
                                        value={rechnung}
                                        onChange={(e)=>setRechnung(e.target.value)}
                                        />
                                    </div>
                                    </div>
                                    <div className='col-span-5 sm:col-span-2'>
                                    <label htmlFor="datepickerId" className="block text-sm font-medium leading-6 text-gray-900">
                                        Rückdatieren
                                    </label>
                                    <div className="mt-2">
                                        <Datepicker 
                                        startWeekOn="mon" 
                                        i18n={"de"}
                                        popoverDirection="up" 
                                        toggleClassName="absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed top-0"
                                        inputClassName="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        displayFormat={"DD.MM.YYYY"} 
                                        startFrom={new Date()}
                                        placeholder='10.02.2023'
                                        useRange={false} 
                                        asSingle={true}
                                        value={value} 
                                        onChange={handleValueChange} 
                                        /> 
                                        {/* <input
                                        id="datepickerId"
                                        type="text"
                                        name="nummer"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="10.02.2023"
                                        value={date}
                                        onChange={(e)=>setDate(e.target.value)}
                                        /> */}
                                    </div>
                                    </div>
                                    <div className='col-span-5 sm:col-span-2'>
                                    <label htmlFor="altadress" className="block text-sm font-medium leading-6 text-gray-700">
                                        Abweichende Adresse
                                    </label>
                                    <select
                                        id="altadress"
                                        name="altadress"
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        defaultValue={selectedAdress}
                                        onChange={(e)=>setSelectedAdress(e.target.value)}
                                    >
                                        <option value="">nicht ausgewählt</option>
                                        {adress?.map((adress)=>{
                                            return <option value={adress.id}>{adress.straße}</option>
                                        })

                                        }
                                    </select>
                                    </div>
                                    </div>
                                </Disclosure.Panel>
                            </>
                            )}
                        </Disclosure>
                    </dl>

                        </div>
                        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                        <button
                            type="submit"
                            disabled={projects.length > 0?false:true || loading?true:false}
                            className={classNames("inline-flex justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2",projects.length > 0?"bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500":"bg-indigo-300  focus:ring-indigo-300" )}
                        >{loading?
                            <span className='flex'><svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg> Lädt...</span>:
                            <span>

                            Beleg für {formatter.format(aufwand? parseFloat(aufwand) + parseFloat(projects.reduce(function (acc, obj) { return acc + obj.gesamt; }, 0)):projects.reduce(function (acc, obj) { return acc + obj.gesamt; }, 0))} erstellen</span>
                            }
                        </button>
                        </div>
                    </div>
                    </form>

                </div>
    </>
  )
}

export default Create