import React, { useState, Fragment, useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  XMarkIcon,
  CogIcon,
  ShoppingBagIcon,
  MagnifyingGlassCircleIcon,
  ReceiptPercentIcon,
  ListBulletIcon,
  ReceiptRefundIcon,
  UserCircleIcon,
  CurrencyEuroIcon,
  VideoCameraIcon,
  ClipboardDocumentCheckIcon,
  ChevronRightIcon,
  BellAlertIcon
} from '@heroicons/react/24/outline'
import { BugContext } from './context'


const navigation = [
  { name: 'Belege', href: 'header', admin:"User", icon: ReceiptPercentIcon, current: false},
  { name: 'Beleg erstellen', href: '/create', admin:"User",icon: ReceiptPercentIcon, current: false},
  // { name: 'Gesuche', href: '/lookup', icon: MagnifyingGlassCircleIcon, current: false},
  { name: 'Bestandslisten', href: '/stock', admin:"User",icon: ListBulletIcon, current: false },
    { name: 'Bestand aktualisieren', href: '/stock/update', sub:true,admin:"Admin", icon: ChevronRightIcon, current: false },
    { name: 'Produkt hinzufügen', href: '/create/new', sub:true,admin:"Admin", icon: ChevronRightIcon, current: false },
  { name: 'Kunden', href: 'header',admin:"User", icon: ReceiptPercentIcon, current: false},
  { name: 'Kundenkartei', href: '/customers',admin:"User", icon: UserCircleIcon, current: false },
  { name: 'Informationen', href: 'header',admin:"User", icon: ReceiptPercentIcon, current: false},
  { name: 'Preislisten', href: '/pricing',admin:"User", icon: CurrencyEuroIcon, current: false },
  { name: 'Videos', href: '/videos', icon: VideoCameraIcon,admin:"User", current: false },
]

const admin = [
  { name: 'Admin', href: 'header', icon: ReceiptPercentIcon, current: false},
  { name: 'Belege', href: '/receipts', icon: ClipboardDocumentCheckIcon, current: false},
  // { name: 'Gesuche', href: '/lookup', icon: MagnifyingGlassCircleIcon, current: false},
  { name: 'Funktionen', href: '/settings', icon: ChartBarIcon, current: false },
  
]



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Sidebar = (props) => {

  let activeStyle = {
    textDecoration: "underline",
  };

  const [info, setInfo] = useState("")
  const { data, dispatchBugEvent } = useContext(BugContext);

  console.log(info)

  return (
    <>
    <Transition.Root show={props.sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={()=>props.setSidebarOpen(true)}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => props.setSidebarOpen(false)}
                      >
                        <span className="sr-only">Sidebar schließen</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center justify-center px-4">
                    <img
                      className="h-6 w-auto"
                      src="https://meisen.waithere.de/dashboard/RonnieMeisenLogoOnline.png"
                      alt="Ronnie Meisen"
                    />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="px-2">
                      {navigation.filter(el=>el.admin === props.api.role || el.admin === "User").map((item) => (

                        item.href !== "header" ? 
                        <NavLink
                          key={item.name}
                          to={item.href}
                          onClick={() => props.setSidebarOpen(false)}
                          className={({isActive})=>classNames(
                             isActive && !item.sub && props.api.role === "Admin"
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                              item.sub? 'text-sm group rounded-md py-2 px-2 flex items-center font-medium':
                              'group rounded-md py-2 px-2 flex items-center text-base font-medium'
                          )}
                        >
                          {!item.sub?
                          <item.icon
                            className={classNames(
                              item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />:<item.icon className='mr-4 flex-shrink-0 h-3 w-3' />}
                          {item.name}
                        </NavLink> : <h3 className='text-gray-500 font-semibold px-2 uppercase text-xs mb-3 mt-6'>{item.name}</h3>
                      ))}
                      { props.api.role === "Admin" &&
                admin.map((item) => (
                  item.href !== "header" ?

                  <NavLink
                    key={item.name}
                    to={item.href}
                    onClick={() => props.setSidebarOpen(false)}
                    className={({isActive})=>classNames(
                      isActive ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group rounded-md py-2 px-2 flex items-center text-sm font-medium'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </NavLink> : <h3 className='text-gray-500 font-semibold px-2 uppercase text-xs mt-6 mb-3'>{item.name}</h3>
                ))
              }
                    </nav>
                    
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
            <div className="flex flex-shrink-0 items-center justify-center px-4">
              <img
                className="h-12 w-auto"
                src="https://meisen.waithere.de/dashboard/RonnieMeisenLogoOnline.png"
                alt="Ronnie Meisen"
              />
            </div>
            <div className="mt-5 flex flex-grow flex-col">
              <nav className="flex-1 px-2 pb-4">
                {navigation.filter(el=>el.admin === props.api.role || el.admin === "User").map((item) => (
                  item.href !== "header" ? 
                  <NavLink
                    key={item.name}
                    to={item.href}
                    onClick={() => props.setSidebarOpen(false)}
                    className={({isActive})=>classNames(
                      isActive && !item.sub && props.api.role === "Admin"
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        item.sub? 'ml-4 text-sm group rounded-md py-2 px-2 flex items-center font-medium':
                      'group rounded-md py-2 px-2 flex items-center text-base font-medium'
                    )}
                  >
                    {!item.sub?
                    <item.icon
                      className={classNames(
                        item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                        'mr-4 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />:<item.icon className='mr-4 flex-shrink-0 h-3 w-3' />}
                    {item.name}
                  </NavLink> : <h3 className='text-gray-500 font-semibold px-2 uppercase text-xs mt-6 mb-3'>{item.name}</h3>
                ))}
                { props.api.role === "Admin" &&
                admin.map((item) => (
                  item.href !== "header" ?
                  <NavLink
                    key={item.name}
                    to={item.href}
                    onClick={() => props.setSidebarOpen(false)}
                    className={({isActive})=>classNames(
                      isActive ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group rounded-md py-2 px-2 flex items-center text-sm font-medium'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </NavLink> : <h3 className='text-gray-500 font-semibold px-2 uppercase text-xs mt-6 mb-3'>{item.name}</h3>
                ))
              }
              
              </nav>
              <nav className='px-2 pb-4'>
              <div className="rounded-md bg-blue-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <BellAlertIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                  </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                      <div className='flex flex-col'>
                      <p className="text-sm text-blue-700">Fehler melden in</p>
                      <p className='text-xs text-blue-700 font-bold'>{data.length > 0 ?data.map(e=>{ return <span className='block'>{e.url}</span>}):"no data"}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                      
                      <div className="my-2">
                        <input
                          type="text"
                          name="text"
                          id="feedback"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Problembeschreibung"
                          onChange={(e)=>setInfo(e.target.value)}
                          onBlur={()=>dispatchBugEvent('REPORT', {report: info})}
                          value={info}
                        />
                      </div>
                    </div>
                    <p className="flex gap-2 mt-3 text-sm md:mt-0 justify-end">
                        <button onClick={()=>{dispatchBugEvent('SEND')}} type='button' className="text-xs whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                          {data[0]?.response?data[0].response:"Senden"}
                          <span aria-hidden="true"> &rarr;</span>
                        </button>
                      </p>
              </div>
              </nav>
              
              
            </div>
            
          </div>
          
        </div>
        </>
  )
}

export default Sidebar